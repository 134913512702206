import React, { useContext } from "react";
import ProductItem from "../ProductItem";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { AppContext } from "../../context/AppContext";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { toSeoUrl } from "../../constants";

function ProductScreenDetails({ advert, latests, title, id }) {
  const { formatNumber, favourite } = useContext(AppContext);
  const setFavourite = (id) => {
    advert.isFavourite = !advert.isFavourite;
    favourite(id);
  };
  return (
    <div id="content" className="col-md-12">
      <div
        style={{
          textAlign: "center",
          display: advert.length === 0 ? "block" : "none",
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <Skeleton style={{ height: 350, width: "100%" }} />
          </div>
          <div className="col-md-6">
            <Skeleton style={{ height: 125, width: "100%" }} />
            <Skeleton style={{ height: 220, width: "100%" }} />
          </div>
        </div>
      </div>
      <div className="product-view">
        <div className="left-content-product">
          <div className="row">
            <div className="content-product-left col-md-8 col-sm-12 col-xs-12">
              <Carousel>
                {advert?.images?.map((value, index) => {
                  return (
                    <img
                      itemProp="image"
                      className="product-image-zoom"
                      src={value}
                      data-zoom-image={value}
                      key={index}
                    />
                  );
                })}
              </Carousel>
            </div>
            <div className="content-product-right col-md-4 col-sm-12 col-xs-12">
              <div className="title-product">
                <h1 className="item-tg">{advert?.title}</h1>

                <div
                  className="form-group box-info-product"
                  style={{ marginTop: 10, float: "none", marginBottom: -8 }}
                >
                  <div className="add-to-links wish_comp">
                    <ul className="blank list-inline">
                      <li>
                        <h2 className="item-price-tg">
                          ₦{formatNumber(advert?.price)}
                        </h2>
                      </li>
                      <li className="wishlist">
                        <a
                          className={`icon ${
                            advert?.isFavourite ? "isFavourite" : ""
                          }`}
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Add to Wish List"
                          onClick={(e) => {
                            e.preventDefault();
                            setFavourite(advert?.id);
                          }}
                        >
                          <i className="fa fa-heart" />
                        </a>
                      </li>
                      <li className="compare">
                        <a
                          className="icon"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Compare this Product"
                        >
                          <i className="fa fa-exchange" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="line bt-30" />

              <div className="flex">
                <div className="div">
                  <h6 className="condition">
                    <img
                      src="/image/mmark.19074ad6.svg"
                      className="img-fluid mmark"
                      alt="mark"
                    />
                    Item Condition
                  </h6>
                  <span className="condition-query">
                    {advert?.condition?.name}
                  </span>
                </div>
                <div className="div">
                  <h6 className="condition">
                    <img
                      src="/image/mmark.19074ad6.svg"
                      className="img-fluid mmark"
                      alt="mark"
                    />
                    Category
                  </h6>
                  <span className="condition-query">
                    {advert?.category?.name}
                  </span>
                </div>
              </div>
              <div className="flex">
                <div className="div">
                  <h6 className="condition">
                    <img
                      src="/image/mmark.19074ad6.svg"
                      className="img-fluid mmark"
                      alt="mark"
                    />
                    Brand
                  </h6>
                  <span className="condition-query">
                    {advert?.brand?.name ? advert?.brand?.name : "Null"}
                  </span>
                </div>
                <div className="div">
                  <h6 className="condition">
                    <img
                      src="/image/mmark.19074ad6.svg"
                      className="img-fluid mmark"
                      alt="mark"
                    />
                    Brand Model
                  </h6>
                  <span className="condition-query">
                    {advert?.brandmodel?.name}
                  </span>
                </div>
              </div>

              {/* SELLER */}
              <div className="line" />
              {advert?.dealer && (
                <Link
                  to={`/dealer-adverts/${advert?.dealer_id}/${toSeoUrl(
                    advert?.dealer?.name
                  )}`}
                >
                  <h6 className="seller-title">
                    Listed by <span>{advert?.dealer?.name}</span>
                  </h6>
                  {advert?.dealer?.user?.avatar ? (
                    <img
                      src={advert?.dealer?.user?.avatar}
                      className="img-fluid rounded-circle profile"
                      alt="profileface"
                    />
                  ) : (
                    <img
                      src="/image/user.gif"
                      className="img-fluid rounded-circle profile"
                      alt="profileface"
                    />
                  )}
                </Link>
              )}

              {advert?.dealer_adverts_count != 0 && (
                <Link
                  to={`/dealer-adverts/${advert?.dealer_id}/${toSeoUrl(
                    advert?.dealer?.name
                  )}`}
                >
                  <h6 className="label-listing">
                    <span className="label-listing-counter">
                      +{advert?.dealer_adverts_count}
                    </span>
                    More listings by this dealer
                  </h6>
                </Link>
              )}
              <h6 className="seller-verify">
                <img
                  src="/image/badge.ad28ee77.svg"
                  className="img-fluid badge-2"
                  alt="badge"
                />
                Verified dealer
              </h6>
              <h6 className="seller-verify">
                <img
                  src="/image/phone.fd84841a.svg"
                  className="img-fluid badge-2"
                  alt="phone"
                />
                {advert?.dealer?.phone}
              </h6>
              <h6 className="seller-verify">
                <img
                  src="/image/pin.png"
                  className="img-fluid badge-2"
                  alt="phone"
                />
                {advert?.address != null && `${advert?.address?.address}`}
                {advert?.address?.city != null && `, ${advert?.address?.city}`}
                {advert?.address?.state != null &&
                  `, ${advert?.address?.state}.`}
              </h6>
              <div className="line" />
              {/* END SELLER */}

              {/* end box info product */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <h2 style={{ marginTop: 40 }}>Description</h2>
          {advert?.description}

          <h3 className="things">Disclaimer</h3>
          <p className="things-2">
            Ensure you confirm your item before making payments if you must meet
            the seller, kindly do so in a public space or verified store for a
            safer transaction mainautoparts would NOT be liable for any
            transaction between Seller and the buyer.
          </p>
        </div>

        <div className="col-md-4">
          <h6 className="share">Share with friends</h6>
          <a
            href={`https://api.whatsapp.com/send?phone=&text= Hey, can you check out this ad on maine-autopart, here's is the link https://maineautoparts.com/${title}/${id}`}
            rel="noreferrer noopener"
          >
            <img
              src="/image/share.c25a5ef7.svg"
              className="img-fluid share-icon"
              alt="share"
            />
          </a>
          <h3 className="things">Things to know</h3>
          <p className="things-2">Some few tips</p>
          <h6 className="text-tips">
            <img
              alt=""
              src="/image/work.d3f16415.svg"
              className="img-fluid work"
            />
            Buy safely
          </h6>
          <h6 className="text-tips">
            <img
              alt=""
              src="/image/event.1cb272a4.svg"
              className="img-fluid work-22"
            />
            Check item condition
          </h6>
          <h6 className="text-tips">
            <img
              alt=""
              src="/image/bank.b41206d1.svg"
              className="img-fluid work-22"
            />
            Make payment only when you see item(s)
          </h6>
        </div>
      </div>
      {/* Product Tabs */}
      {/* //Product Tabs */}
      {/* Related Products */}
      <div className="line btp-70" />

      <div className="related titleLine products-list grid module ">
        <h1 className="modtitle" style={{ marginBottom: 30 }}>
          Related Products
        </h1>
        <div
          className="row"
          style={{
            textAlign: "center",
            display: latests.length !== 0 ? "none" : "block",
          }}
        >
          <div className="col-md-4">
            <Skeleton style={{ height: 350, width: "100%" }} />
          </div>
          <div className="col-md-4">
            <Skeleton style={{ height: 350, width: "100%" }} />
          </div>
          <div className="col-md-4">
            <Skeleton style={{ height: 350, width: "100%" }} />
          </div>
        </div>
        <div className="row">
          {latests.map((value, index) => {
            return (
              <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                <div className="product-layout product-grid">
                  <ProductItem item={value} img2 />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* end Related  Products*/}
    </div>
  );
}

export default ProductScreenDetails;

import React, { useState } from "react";
import CatScreenDetails from "../components/Category/CatScreenDetails";
import CatScreenSideBar from "../components/Category/CatScreenSideBar";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Footer from "./Layout/Footer";
import { toNormal } from "../constants";

function DealerAdverts(props) {
  let params = useParams();
  const [adverts, setAdverts] = React.useState([]);
  const [latests, setLatests] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [total, settotal] = React.useState(0);
  React.useEffect(() => {
    let link = `https://dashboard.maineautoparts.com/api/dealer-adverts/${params.dealer_id}/${params.dealer_name}`;
    axios.get(link).then((response) => {
      let ads = response.data.data;
      console.log(response.data.next_page_url);
      setNextPage(response.data.next_page_url);
      settotal(response.data.total);
      setAdverts(ads);
      setLoading(false);
    });
  }, [params]);

  return (
    <div>
      <div className="main-container container">
        <ul className="breadcrumb">
          <li>
            <Link to="/">
              <i className="fa fa-home"></i>
            </Link>
          </li>
          <li>
            <Link to="#">{params.dealer_name}</Link>
          </li>
        </ul>
        <div className="row">
          {/*Middle Part Start*/}
          <div id="content" className="col-md-9">
            <CatScreenDetails
              ads={adverts}
              category={toNormal(params.dealer_name)}
              nextPageUrl={nextPage}
              four
              loading={loading}
              small="ads by"
            />
          </div>
          {/*Side Bar*/}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DealerAdverts;

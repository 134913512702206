import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useNavigate } from "react-router-dom";

function Main(props) {
  const { categories, brands, conditions, searchAds } = useContext(AppContext);
  let navigate = useNavigate();

  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [condition, setCondition] = React.useState("");
  const [minPrice, setMinPrice] = React.useState("");
  const [maxPrice, setMaxPrice] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [brandModel, setBrandModel] = React.useState([]);
  const [brandModels, setBrandModels] = React.useState([]);

  return (
    <div className="main-hold">
      <div className="so_advanced_search">
        <div className="sas_inner-box-search">
          <div className="container m-container">
            <div className="row">
              <div className="col-md-6 sm-show">
                <div className="main-info">
                  <img src="/image/arrow.svg" />
                  <h1>Discover & buy your new car parts.</h1>
                  <p>Affordable & genuine everyday motor parts</p>
                  <div>
                    <img src="/image/appstore.svg" className="img2" />
                    <img src="/image/playstore.png" className="img2" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="white">
                  <div className="search-boxes" style={{ width: "100%" }}>
                    <input
                      className="form-control"
                      placeholder="Item name e.g fuel pump"
                      onChange={(event) => setTitle(event.target.value)}
                    />
                  </div>
                  <div className="flexBox">
                    <div className="search-boxes" style={{ width: "49%" }}>
                      <input
                        className="form-control"
                        placeholder="Min price"
                        onChange={(event) => setMinPrice(event.target.value)}
                      />
                    </div>

                    <div className="search-boxes" style={{ width: "49%" }}>
                      <input
                        className="form-control"
                        placeholder="Max price"
                        onChange={(event) => setMaxPrice(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="search-boxes" style={{ width: "100%" }}>
                    <select
                      className="form-control"
                      onChange={(event) => setCategory(event.target.value)}
                    >
                      <option value="">--Select Category--</option>
                      {categories?.map((value, index) => {
                        return (
                          <option key={index} value={value.id}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="search-boxes" style={{ width: "100%" }}>
                    <select
                      className="form-control"
                      id="so_make0"
                      onChange={(event) => {
                        let obj = JSON.parse(event.target.value);
                        setBrand(obj.id);
                        setBrandModels(obj.brand_models);
                      }}
                    >
                      <option value="">--Select Brand--</option>
                      {brands?.map((value, index) => {
                        return (
                          <option key={index} value={JSON.stringify(value)}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {brand != "" ? (
                    <div className="search-boxes" style={{ width: "100%" }}>
                      <select
                        className="form-control"
                        onChange={(event) => {
                          setBrandModel(event.target.value);
                        }}
                      >
                        <option value="">--Select Brand Model</option>
                        {brand === "" ? (
                          <option value="">Select brand first</option>
                        ) : (
                          <></>
                        )}
                        {brandModels?.map((value, index) => {
                          return (
                            <option key={index} value={value.id}>
                              {value.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="search-boxes" style={{ width: "100%" }}>
                    <select
                      className="form-control"
                      value={condition}
                      onChange={(event) => {
                        setCondition(event.target.value);
                      }}
                    >
                      <option value="">--Select Condition</option>=
                      {conditions?.map((value, index) => {
                        return (
                          <option key={index} value={value.id}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="search-button">
                    <button
                      type="button"
                      id="sas_search_button0"
                      onClick={() => {
                        searchAds(category, brand, minPrice, maxPrice, title);
                        navigate("../search", { replace: true });
                      }}
                    >
                      Find My Part
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 sm-no">
                <div className="main-info">
                  <img src="/image/arrow.svg" />
                  <h1>Discover & buy your new car parts.</h1>
                  <p>Affordable & genuine everyday motor parts</p>
                  <div>
                    <a
                      href="https://apps.apple.com/in/app/maine-auto-parts/id6474206760"
                      target="_blank"
                    >
                      <img src="/image/appstore.svg" className="img2" />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.maineautoparts"
                      target="_blank"
                    >
                      <img src="/image/playstore.png" className="img2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;

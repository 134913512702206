import React, { useContext } from "react";
import { CgUser } from "react-icons/cg";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import $ from "jquery";
import { AppContext } from "../../context/AppContext";
function Header(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const [user, setUser] = React.useState("");
  const { reload } = useContext(AppContext);
  const [shown, setShown] = React.useState(false);
  React.useEffect(() => {
    let u = localStorage.getItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    u = JSON.parse(u);
    if (u != null) {
      setUser(u);
    } else {
      setUser("");
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setShown(false);
    console.log(shown);
  }, [location, reload]);

  const signOut = () => {
    localStorage.removeItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    document.getElementById("toggle").classList.remove("switch");
    navigate("../login", { replace: true });
  };

  return (
    <header id="header" className="typeheader-5">
      {/* Header Top */}
      <div className="hidden-compact">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="flex">
                <div className="logo">
                  <Link to="/">
                    <img src="/image/catalog/logo.png" />
                  </Link>
                </div>
                {user ? (
                  <>
                    <div className="header-drop">
                      <div
                        id="dropClick"
                        onClick={() => {
                          setShown(!shown);
                        }}
                      >
                        <p>Hi, {user?.user?.first_name}</p>
                        <img
                          src={
                            user?.user?.avatar != undefined
                              ? user.user.avatar.image
                              : `/image/user.gif`
                          }
                        />
                      </div>
                      <div
                        className="header-dropdown no-c"
                        style={{ display: shown ? "block" : "none" }}
                      >
                        <Link to="/dashboard" className="no-c">
                          Dashboard
                        </Link>
                        <a
                          onClick={() => {
                            signOut();
                          }}
                          className="no-c"
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="c-header">
                    <Link to="/login">Sign In</Link>
                    <span></span>
                    {!user ? <Link to="/register">Register</Link> : <></>}
                    <Link to="/request" className="c-btn">
                      Request for Autoparts
                    </Link>
                  </div>
                )}
                <div className="toggle-menu" id="toggle">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

import React, { useContext } from "react";
import CatScreenDetails from "../components/Category/CatScreenDetails";
import CatScreenSideBar from "../components/Category/CatScreenSideBar";
import { useParams, Link } from "react-router-dom";
import Footer from "./Layout/Footer";
import { AppContext } from "../context/AppContext";
import axios from "axios";

function Search(props) {
  let params = useParams();
  const { searchedAdverts, reset, setReset } = useContext(AppContext);
  const [latests, setLatests] = React.useState([]);
  const [search, setSearchs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [nextPage, setNextPage] = React.useState(null);
  const [total, settotal] = React.useState(0);
  React.useEffect(() => {
    axios
      .get(
        "https://dashboard.maineautoparts.com/api/search-adverts?featured=0&category_id=&brand_id=&dealer_id&minimumPrice=&maximumPrice&search="
      )
      .then((response) => {
        let ads = response.data.data;
        setNextPage(response.data.next_page_url);
        settotal(response.data.total);
        setSearchs(ads);
        ads = ads.slice(0, 5);
        setLatests(ads);
      });
    setReset(!reset);
  }, [params]);

  return (
    <div>
      <div className="main-container container">
        <ul className="breadcrumb">
          <li>
            <Link to="/">
              <i className="fa fa-home"></i>
            </Link>
          </li>
          <li>
            <Link to="#">SEARCH</Link>
          </li>
        </ul>

        <div className="row">
          {/*Middle Part Start*/}
          <div id="content" className="col-md-9 col-sm-8">
            <CatScreenDetails
              ads={searchedAdverts.length != 0 ? searchedAdverts : search}
              nextPageUrl={nextPage}
              category="SEARCH"
              total={total}
            />
          </div>
          {/*Side Bar*/}
          <CatScreenSideBar latests={latests} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Search;

import React from "react";
import Footer from "./Layout/Footer";

function About(props) {
  return (
    <div>
      <div className="container page">
        <div className="row">
          <div className="col-md-6">
            <img
              src="/image/lagos.7a3bd977.png"
              className="img-fluid astra"
              alt="lagos"
            />
            <img
              src="/image/earnings.d38c6955.png"
              className="img-fluid hide-mobile astra astra-bottom"
              alt="lagos"
            />
          </div>
          <div className="col-md-6">
            <h3 className="astra-text">
              What makes us exceptional from others.
            </h3>
            <p className="astra-body">
              “This is where they get it cheaper” That Engine they sell ₦250,000
              In Auto Market is ₦180,000 at MaineAutoParts.
            </p>
            <p className="astra-body">
              Yes, it’s Possible and it’s the reason why they kept this secret
              from you MaineAutoParts Gives You Access to over 200 Spare Parts
              Dealers and Importers with a wider coverage of customers Across
              Nigeria Nothing beats having access to Vehicle Spare parts from
              Dealers and Importers willing to sell Cheaper with the Best
              Quality Possible If you don’t understand Let us break it down for
              you This is where the re-sellers get the spare parts at cheaper
              rate to sell to you and this where the buyers come to buy because
              it’s cheaper and faster to get the “mouth-watery deals”
            </p>
            <p className="astra-body">
              The Secret is now open to you, MaineAutoParts made it so it’s now
              up to you! To Get Started See how much your Vehicle Spare Part
              cost here Are you a dealer of Automobile spare parts?{" "}
              <span>
                <a className="guide" href="/signup">
                  Register here
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;

import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ImBin2, ImPencil } from "react-icons/im";
import { AppContext } from "../../context/AppContext";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withSwal } from "react-sweetalert2";

export default withSwal((props, ref) => {
  const { adverts, title, subtitle, swal, reload } = props;
  const { formatNumber } = useContext(AppContext);
  const [swalProps, setSwalProps] = React.useState({});
  const navigate = useNavigate();
  const deleteAdvert = (id) => {
    let user = localStorage.getItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    user = JSON.parse(user);
    swal
      .fire({
        show: true,
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: "delete!",
        preConfirm: () => {
          return fetch(
            `https://dashboard.maineautoparts.com/api/advert/${id}`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${user.accessToken}`,
              },
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              console.log(response);
              return response.json();
            })
            .catch((error) => {
              swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then((result) => {
        if (result.isConfirmed) {
          reload();
          swal.fire({
            title: "Advert deleted",
          });
        }
      });
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        darkmode
      />
      <div className="title">
        <h1>
          {title} <span>{subtitle}</span>
        </h1>
      </div>
      <div className="main">
        <div className="row">
          {adverts?.data?.length == 0 || adverts.data == undefined ? (
            <img src="/image/empty.svg" className="empty" />
          ) : (
            adverts?.data?.map((value, index) => {
              return (
                <div className="col-md-6" key={index}>
                  <Link
                    to={"/dashboard/advert/" + value.id}
                    target="_self"
                    title={value.title}
                  >
                    <div className="listing">
                      <div className="list-img-hold">
                        {value.images[0] ? (
                          <img
                            src={value.images[0]}
                            className="img-1 img-responsive"
                            alt="image1"
                          />
                        ) : (
                          <div className="imgBlack"></div>
                        )}
                      </div>
                      <div className="text">
                        <h2>{value.title}</h2>
                        <h3>₦{formatNumber(value.price)}</h3>
                        <p
                          className="condit"
                          style={{
                            position: "absolute",
                            bottom: 2,
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          {value.condition.name}
                        </p>
                      </div>
                      <div className="buttons">
                        <button
                          className="edit-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/dashboard/edit-advert/" + value.id);
                          }}
                        >
                          <ImPencil /> Edit
                        </button>
                        <button
                          className="del-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            deleteAdvert(value.id);
                          }}
                        >
                          <ImBin2 /> Delete{" "}
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      </div>

      <div className="bottom">
        <p>
          Showing {adverts.from}-{adverts.to} of {adverts.total}
        </p>
        <div>
          <button type="button" className="btn btn-outline-primary">
            Prev
          </button>
          <button type="button" className="btn btn-outline-primary">
            Next
          </button>
        </div>
      </div>
    </div>
  );
});

import React from "react";
import { Link } from "react-router-dom";
import ItemsCarousel from "react-items-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toSeoUrl } from "../../constants";

function SoCategories({ categories }) {
  const [activeItemIndex, setActiveItemIndex] = React.useState(0);
  const chevronWidth = 60;
  let width = window.innerWidth;
  console.log(width);
  return (
    <div className="container">
      <div
        id="so_categories_51"
        className="so-categories module theme3 slider-cates5"
      >
        <h1 className="mh1">
          <span>TOP CATEGORIES</span>
        </h1>
        <div
          className="row"
          style={{
            textAlign: "center",
            display: categories.length !== 0 ? "none" : "block",
          }}
        >
          <div className="col-md-3">
            <Skeleton style={{ height: 130, width: 130, borderRadius: 130 }} />
            <Skeleton style={{ height: 10, width: 90 }} />
          </div>
          <div className="col-md-3">
            <Skeleton style={{ height: 130, width: 130, borderRadius: 130 }} />
            <Skeleton style={{ height: 10, width: 90 }} />
          </div>
          <div className="col-md-3">
            <Skeleton style={{ height: 130, width: 130, borderRadius: 130 }} />
            <Skeleton style={{ height: 10, width: 90 }} />
          </div>
          <div className="col-md-3">
            <Skeleton style={{ height: 130, width: 130, borderRadius: 130 }} />
            <Skeleton style={{ height: 10, width: 90 }} />
          </div>
        </div>
        <div style={{ padding: `0 ${chevronWidth}px` }}>
          <div className="cat-wrap theme3">
            {categories.length !== 0 ? (
              <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={width > 768 ? 5 : 1}
                gutter={10}
                leftChevron={
                  <button className="swipe-btn">
                    <i className="fa fa-angle-left"></i>
                  </button>
                }
                rightChevron={
                  <button className="swipe-btn">
                    <i className="fa fa-angle-right"></i>
                  </button>
                }
                outsideChevron
                chevronWidth={chevronWidth}
              >
                {categories.map((value, index) => {
                  return (
                    <div className="content-box" key={index}>
                      <div className="image-cat">
                        <Link
                          to={
                            "/category/" +
                            toSeoUrl(value.name) +
                            "/" +
                            value?.id
                          }
                        >
                          <img
                            src={value.icon}
                            alt={value.icon}
                            style={{
                              width: 100,
                              height: 100,
                              objectFit: "contain",
                              margin: "0 auto",
                            }}
                          />
                        </Link>
                      </div>
                      <div className="cat-title">
                        <Link to={"/category/" + value.name + "/" + value?.id}>
                          {value.name}
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </ItemsCarousel>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoCategories;

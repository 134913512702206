import React, { useContext } from "react";
import CatProduct from "./CatProduct";
import Filter from "./Filter";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import Overlay from "../Overlay";
import { AppContext } from "../../context/AppContext";

function CatScreenDetails({
  ads,
  category,
  nextPageUrl,
  small,
  four,
  loading,
}) {
  const [grid, setGrid] = React.useState(true);
  const { display, searchtitle, setReset, reset } = useContext(AppContext);

  const [adverts, setAdverts] = React.useState([]);
  const [loadingNext, setLoadingNext] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [nextPage, setNextPage] = React.useState(null);
  const [total, settotal] = React.useState(0);

  const fetchData = () => {
    if (nextPage != null) {
      setLoadingNext(true);
      axios.get(nextPage).then((response) => {
        let ads = response.data.data;
        setNextPage(response.data.next_page_url);
        var adv = [...adverts];
        ads.forEach((ad) => {
          adv.push(ad);
        });
        setAdverts(adv);
        setLoadingNext(false);
      });
    }
  };
  React.useEffect(() => {
    const set = () => {
      setNextPage(nextPageUrl);
      setAdverts(ads);
    };
    set();
  }, [ads, nextPageUrl]);
  return (
    <div className="products-category">
      <h3 className="title-category " style={{ fontSize: 25 }}>
        <span style={{ fontSize: 20, fontWeight: 300 }}>{small} </span>
        {category}
      </h3>
      {searchtitle != null && searchtitle != "" ? (
        <p style={{ marginTop: -20 }}>Search for {searchtitle}</p>
      ) : (
        <></>
      )}

      {/*
        <div className="category-desc">
          <div className="row">
            <div className="col-sm-12">
              <div className="banners">
                <div>
                  <a href="#">
                    <img
                      src="/image/catalog/demo/category/img-cate.jpg"
                      alt="img cate"
                    />
                    <br />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
      {/* Filters */}
      <Filter
        setGrid={() => {
          setGrid(true);
          console.log("dd");
        }}
        setList={() => {
          setGrid(false);
          console.log("dd2" + grid);
        }}
      />
      {/* //end Filters */}

      <div
        className="row"
        style={{
          textAlign: "center",
          display: loading ? "block" : "none",
        }}
      >
        <div className="col-md-4">
          <Skeleton style={{ height: 350, width: "100%" }} />
        </div>
        <div className="col-md-4">
          <Skeleton style={{ height: 350, width: "100%" }} />
        </div>
        <div className="col-md-4">
          <Skeleton style={{ height: 350, width: "100%" }} />
        </div>
      </div>

      {/*changed listings*/}
      <div
        className={`
          products-list row nopadding-xs so-filter-gird  ${
            grid ? "grid" : "list"
          }
        `}
      >
        <InfiniteScroll
          dataLength={adverts.length} //This is important field to render the next data
          next={fetchData}
          hasMore={nextPage ? true : false}
          loader={<h4>Loading More...</h4>}
        >
          {adverts.map((value, index) => {
            return <CatProduct item={value} key={index} grid={grid} />;
          })}
        </InfiniteScroll>
      </div>
      {/*// End Changed listings*/}
      {/* Filters */}
      <div className="product-filter product-filter-bottom filters-panel">
        <div className="row">
          <div className="col-sm-6 text-left" />
          {/*
            <div className="col-sm-6 text-right">
              Showing 1 to 12 of 12 (1 Pages)
            </div>*/}
        </div>
      </div>
      {/* //end Filters */}
      <Overlay display={display} />
    </div>
  );
}

export default CatScreenDetails;

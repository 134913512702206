import React from "react";
import Footer from "./Layout/Footer";

function Career(props) {
  return (
    <div>
      <div className="container page">
        <div className="row">
          <div className="col-lg-6">
            <img
              src="/image/careers.fe122935.jpg"
              className="img-fluid img-career"
              alt="careers"
            />
          </div>
          <div className="col-lg-6">
            <h3 className="careers-h">Careers</h3>
            <h3 className="careers-header">
              A space to share, a world to gain.
            </h3>
            <p className="careers-t">
              We're always looking out for the best, vibrant &amp; bright minds
              to work &amp; share with.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <h3 className="careers-mx">Our Mission</h3>
            <h3 className="careers-mx-x">
              Create a world where anyone can belong anywhere.
            </h3>
            <p className="careers-t-x">
              It’s an audacious, incredibly rewarding mission that our
              increasingly diverse team is dedicated to achieving.
            </p>
            <p className="careers-t-x">
              Maineauto parts is built around the idea that everyone should be
              able to make that perfect purchase, for both new and fairly used
              autoparts To that end, we empower millions of people around
              Nigeria and later outside Nigeria to use their spaces, passions,
              and talents to become entrepreneurs.
            </p>
            <p className="careers-t-x">
              Exciting challenges lie ahead—new regions, technologies, and
              businesses. Guided by our four core values, we’ll meet these
              challenges creatively and with the support of our global
              community. Join us!
            </p>
          </div>
          <div className="col-lg-3" />
        </div>

        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6">
            <h3 className="careers-mx">Work with us</h3>
            <h3 className="careers-mx-x">Open roles &amp; positions.</h3>
            <p className="careers-t-x">
              Sorry, there is currently no open roles or positions at the
              moment; Kindly keep tab of this space or check back at later
              weekly intervals for updates on openings.
            </p>
          </div>
          <div className="col-md-3" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Career;

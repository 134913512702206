import React from "react";

function Overlay({ display }) {
  return (
    <div style={{ display: display ? "block" : "none" }}>
      <div id="pg-overlay" className="overlay">
        <span
          className="spinner-border spinner-border-sm onPageloader"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
  );
}

export default Overlay;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register(props) {
  const params = useParams();
  const [Firstname, setFirstname] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [PasswordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [timer, settimer] = useState(60);
  const [verifyError, setVerifyError] = useState("");
  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("password", Password);
    formData.append("password_confirmation", PasswordConfirmation);
    formData.append("email", Email);
    formData.append("phone", Phone);
    formData.append("first_name", Firstname);
    formData.append("last_name", Lastname);
    formData.append("is_company", 1);
    axios
      .post(
        "https://dashboard.maineautoparts.com/api/register-dealer",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        setLoading(false);
        setSuccess(true);
        toast.success("Message Sent!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        //// Run Job Schedule
        axios.get("https://dashboard.maineautoparts.com/api/run");
        setTimeout(function () {
          axios.get("https://dashboard.maineautoparts.com/api/run");
        }, 1000);
        setTimeout(function () {
          axios.get("https://dashboard.maineautoparts.com/api/run");
        }, 10000);
        //////////////////

        if (timer == 60) {
          let t = 10;
          setInterval(() => {
            if (t > 0) {
              t = t -= 1;
              settimer(t);
            }
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error("Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        setErrors(error.response.data.errors);
      });
  };

  const resendVerification = () => {
    if (timer != 0) {
      return false;
    }
    settimer(60);
    setLoading(true);
    axios
      .post("https://dashboard.maineautoparts.com/api/resendVerification", {
        email: Email,
      })
      .then(() => {
        setLoading(false);
        toast.success("Verification code sent!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        if (timer == 60) {
          let t = 10;
          setInterval(() => {
            if (t > 0) {
              t = t -= 1;
              settimer(t);
            }
          }, 1000);
        }
      })
      .catch((error) => {
        setVerifyError(error.response.data.message);
        setLoading(false);
        toast.error("Error", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  useEffect(() => {
    setErrors([]);
  }, [params]);
  return (
    <div className="container mb-100">
      <ToastContainer />
      <div className="row">
        <div className="col-lg-12">
          <div className="card-sign-up">
            <h3 className="form-title">
              {success ? "Verify email address" : "Sign up now. it's free"}
            </h3>
            {success ? (
              <div className="text-center">
                <p>
                  We've sent a verification email to your email address, visit
                  your mailbox to verify your account.
                </p>
                <img src="/image/success.gif" style={{ maxWidth: 200 }} />
                <p className="timer">
                  <span className="error">{verifyError}</span>
                  <br></br>
                  <strong>Did'nt get code? </strong>
                  <span
                    className={timer > 0 ? "inactive" : ""}
                    onClick={() => resendVerification()}
                  >
                    Resend {timer}
                  </span>
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm onPageloader"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </p>
              </div>
            ) : (
              <>
                <form action="#" method="post">
                  {success ? <p className="success">{success}</p> : ""}
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        name="first_name"
                        type="text"
                        className={[
                          "overide input-text ",
                          errors?.first_name ? " is-invalid" : "",
                        ]}
                        placeholder="First name"
                        defaultValue={Firstname}
                        onChange={(event) => setFirstname(event.target.value)}
                      />
                      {errors?.first_name != undefined ? (
                        <p className="error">{errors?.first_name[0]}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6">
                      <input
                        name="last_name"
                        type="text"
                        placeholder="Last name"
                        defaultValue={Lastname}
                        onChange={(event) => setLastname(event.target.value)}
                        className={[
                          "overide input-text ",
                          errors?.last_name ? " is-invalid" : "",
                        ]}
                      />
                      {errors?.last_name != undefined ? (
                        <p className="error">{errors?.last_name[0]}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <input
                    name="phone"
                    type="text"
                    placeholder="Mobile number"
                    defaultValue={Phone}
                    onChange={(event) => setPhone(event.target.value)}
                    className={[
                      "overide input-number ",
                      errors?.phone ? " is-invalid" : "",
                    ]}
                  />
                  {errors?.phone != undefined ? (
                    <p className="error">{errors?.phone[0]}</p>
                  ) : (
                    ""
                  )}
                  <input
                    name="email"
                    type="email"
                    className={[
                      "overide input-email ",
                      errors?.email ? " is-invalid" : "",
                    ]}
                    placeholder="Email address e.g john@gmail.com"
                    defaultValue={Email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  {errors?.email != undefined ? (
                    <p className="error">{errors?.email[0]}</p>
                  ) : (
                    ""
                  )}
                  <input
                    name="password"
                    type="password"
                    placeholder="Choose password"
                    defaultValue={Password}
                    className={[
                      "overide input-password ",
                      errors?.password ? " is-invalid" : "",
                    ]}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  {errors?.password != undefined ? (
                    <p className="error">{errors?.password[0]}</p>
                  ) : (
                    ""
                  )}
                  <input
                    name="password_confirmation"
                    type="password"
                    className="overide input-password"
                    placeholder="Re-type password"
                    defaultValue={PasswordConfirmation}
                    onChange={(event) =>
                      setPasswordConfirmation(event.target.value)
                    }
                  />
                  <button
                    type="submit"
                    className="sign-up-btn"
                    onClick={submit}
                  >
                    Create my account
                    <span
                      className="spinner-border spinner-border-sm onPageloader"
                      role="status"
                      aria-hidden="true"
                      style={{ display: loading ? "inline-block" : "none" }}
                    ></span>
                  </button>
                </form>
              </>
            )}
          </div>
          <p className="terms-form">
            By signing up, I have read &amp; agreed to Maine autopart(s)
            <span className="tt" style={{ marginLeft: 5 }}>
              <Link className="aa-colo-2" to="/terms-of-services">
                Read terms
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;

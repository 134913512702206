import React, { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

function CatProductListing({ title, adverts }) {
  const { formatNumber } = useContext(AppContext);
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
      <div className="module product-simple">
        <h3 className="modtitle">
          <span>{title}</span>
        </h3>

        <div
          className="row"
          style={{
            display: adverts.length !== 0 ? "none" : "block",
          }}
        >
          <div className="col-md-12">
            <div className="row">
              <div className="col-xs-6">
                <Skeleton style={{ height: 130, width: "100%" }} />
              </div>
              <div className="col-xs-6">
                <Skeleton style={{ height: 13, width: "100%" }} />
                <Skeleton style={{ height: 13, width: "100%" }} />
                <Skeleton style={{ height: 13, width: "100%" }} />
                <Skeleton style={{ height: 13, width: "100%" }} />
                <Skeleton style={{ height: 13, width: "100%" }} />
              </div>
            </div>
          </div>
          <div className="col-md-12" style={{ marginTop: 20 }}>
            <div className="row">
              <div className="col-xs-6">
                <Skeleton style={{ height: 130, width: "100%" }} />
              </div>
              <div className="col-xs-6">
                <Skeleton style={{ height: 13, width: "100%" }} />
                <Skeleton style={{ height: 13, width: "100%" }} />
                <Skeleton style={{ height: 13, width: "100%" }} />
                <Skeleton style={{ height: 13, width: "100%" }} />
                <Skeleton style={{ height: 13, width: "100%" }} />
              </div>
            </div>
          </div>
        </div>

        <div className="modcontent">
          <div id="so_extra_slider_31" className="so-extraslider">
            {/* Begin extraslider-inner */}
            <div
              className="yt-content-slider extraslider-inner"
              data-rtl="yes"
              data-autoplay="no"
              data-delay={4}
              data-speed="0.6"
              data-margin={0}
              data-items_column00={1}
              data-items_column0={1}
              data-items_column1={1}
              data-items_column2={1}
              data-items_column3={1}
              data-items_column4={1}
              data-arrows="yes"
              data-pagination="no"
              data-lazyload="yes"
              data-loop="yes"
              data-buttonpage="top"
            >
              <div className="item">
                {adverts.map((item, index) => {
                  return (
                    <div
                      className="product-item-container product-layout item-inner"
                      key={index}
                    >
                      <div className="item-image">
                        <div className="product-image-container second_img">
                          <Link
                            item={item}
                            to={"/advert/" + item.id}
                            className="small-img-hold"
                          >
                            <img
                              src={item.images[0]}
                              className="img-1 img-responsive"
                              alt="image1"
                            />
                            <img
                              src={
                                item.images[1] != undefined
                                  ? item.images[1]
                                  : item.images[0]
                              }
                              className="img-2 img-responsive"
                              alt="image2"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="item-info">
                        <div className="item-title" style={{ marginTop: 0 }}>
                          <Link
                            to={"/advert/" + item.id}
                            title="Doenpuis consuat"
                            target="_self"
                            style={{ fontSize: 15 }}
                          >
                            {item.title}
                          </Link>
                        </div>
                      </div>

                      <div className="price">
                        <span className="price">
                          ₦{formatNumber(item.price)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="item"></div>
            </div>
            {/*End extraslider-inner */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatProductListing;

import axios from "axios";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "./Layout/Footer";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Import the plugin code
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

function Request(props) {
  const params = useParams();

  const titles = [
    {
      name: "General",
    },
    {
      name: "I need a car part",
    },
    {
      name: "I want to be an agent",
    },
    { name: "Price check" },
    {
      name: "I need a mechanic",
    },
    {
      name: "I want to change my car",
    },
    {
      name: "I need an advice",
    },
  ];
  const [name, setName] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [Phone, setPhone] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const submit = (e) => {
    e.preventDefault();

    setLoading(true);
    const formData = new FormData();

    for (var i in files) {
      formData.append("images[]", files[i].file);
    }
    formData.append("title", title);
    formData.append("name", name);
    formData.append("email", Email);
    formData.append("phone", Phone);
    formData.append("description", description);
    formData.append("is_company", 1);
    axios
      .post("https://dashboard.maineautoparts.com/api/enquiry", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setLoading(false);
        toast.success("Request created!", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setSuccess(true);
        setName("");
        setFiles([]);
        setTitle("");
        setPhone("");
        setEmail("");
        setDescription("");
      })
      .catch((error) => {
        setLoading(false);
        setErrors(error.response.data.errors);
        toast.error("Error occured!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };
  React.useEffect(() => {
    setErrors([]);
  }, [params]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        darkmode
      />
      <div className="container mb-100 page">
        <div className="row">
          <div className="col-lg-2">
            <img
              src="/image/face1.ee56825e.png"
              className="img-fluid face float-icons-2"
              alt="face1"
            />
            <img
              src="/image/face2.ffcfda88.png"
              className="img-fluid face2 float-icons-3"
              alt="face2"
            />
            <img
              src="/image/face3.968da958.png"
              className="img-fluid face3 float-icons"
              alt="face3"
            />
          </div>
          <div className="col-lg-8">
            <div className="card-sign-up">
              <h3 className="form-title h-25">Request For AutoPart</h3>
              <p
                className="mb-4"
                style={{ position: "relative", top: -18, textAlign: "center" }}
              >
                Can't find the right autopart, send us a request.
              </p>
              <form action="#" method="post">
                <input
                  name="name"
                  type="text"
                  className={[
                    "overide input-text ",
                    errors.name ? " is-invalid" : "",
                  ]}
                  placeholder="Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                {errors.name != undefined ? (
                  <p className="error">{errors.name[0]}</p>
                ) : (
                  ""
                )}
                <input
                  name="phone"
                  type="text"
                  placeholder="Mobile number"
                  value={Phone}
                  onChange={(event) => setPhone(event.target.value)}
                  className={[
                    "overide input-number ",
                    errors.phone ? " is-invalid" : "",
                  ]}
                />
                {errors.phone != undefined ? (
                  <p className="error">{errors.phone[0]}</p>
                ) : (
                  ""
                )}
                <input
                  name="email"
                  type="email"
                  className={[
                    "overide input-email ",
                    errors.email ? " is-invalid" : "",
                  ]}
                  placeholder="Email address e.g john@gmail.com"
                  value={Email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {errors.email != undefined ? (
                  <p className="error">{errors.email[0]}</p>
                ) : (
                  ""
                )}
                <select
                  name="title"
                  type="text"
                  placeholder="Title"
                  className={[
                    "overide input-email ",
                    errors.title ? " is-invalid" : "",
                  ]}
                  onChange={(event) => setTitle(event.target.value)}
                >
                  <option value="">-- Select Title --</option>
                  {titles.map((value, index) => {
                    return (
                      <option key={index} value={value.name}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
                {errors.title != undefined ? (
                  <p className="error">{errors.title[0]}</p>
                ) : (
                  ""
                )}
                <input
                  name="location"
                  type="text"
                  placeholder="Location"
                  value={location}
                  onChange={(event) => setLocation(event.target.value)}
                  className={[
                    "overide input-text ",
                    errors.location ? " is-invalid" : "",
                  ]}
                />
                {errors.location != undefined ? (
                  <p className="error">{errors.location[0]}</p>
                ) : (
                  ""
                )}
                <textarea
                  name="description"
                  placeholder="Description"
                  value={description}
                  className={`
                  overide text-text
                  ${errors.description ? "is-invalid " : ""},
                `}
                  onChange={(event) => setDescription(event.target.value)}
                ></textarea>
                {errors.description != undefined ? (
                  <p className="error">{errors.description[0]}</p>
                ) : (
                  ""
                )}

                <div
                  className={`custImgUpload
              ${errors?.images ? " is-invalid" : ""}
              ${files.length != 0 ? "" : "addH2"}`}
                  id="cust"
                >
                  <FilePond
                    files={files}
                    onupdatefiles={(fileItems) => {
                      setFiles(fileItems);
                    }}
                    allowMultiple={true}
                    allowReorder={true}
                    maxFiles={3}
                    maxFileSize="1MB"
                    instantUpload={false}
                    allowProcess={false}
                    storeAsFile={true}
                    allowFileTypeValidation={true}
                    allowFileSizeValidation={true}
                    acceptedFileTypes={["image/*"]}
                    //server="/api"
                    name="files"
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  />
                </div>
                <button type="submit" className="sign-up-btn" onClick={submit}>
                  Send Request
                  <span
                    className="spinner-border spinner-border-sm onPageloader"
                    role="status"
                    aria-hidden="true"
                    style={{ display: loading ? "inline-block" : "none" }}
                  ></span>
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-2">
            <img
              src="/image/face4.5e4430b4.png"
              className="img-fluid face4 float-icons-3"
              alt="face4"
            />
            <img
              src="/image/face5.46fcb240.png"
              className="img-fluid face5 float-icons-2"
              alt="face5"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Request;

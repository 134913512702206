import React from "react";
import axios from "axios";
import ProductItem from "../ProductItem";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";

function ListingLayout1(props) {
  const [adverts, setAdverts] = React.useState([]);
  React.useEffect(() => {
    axios
      .get(
        "https://dashboard.maineautoparts.com/api/search-adverts?featured=0&category_id=&brand_id=&dealer_id&minimumPrice=&maximumPrice&search="
      )
      .then((response) => {
        let ads = response.data.data;
        ads = ads.slice(0, 8);
        setAdverts(ads);
      });
  }, []);

  return (
    <div className="module listingtab-layout5">
      <div className="container">
        <h1 className="mh1">
          <span>Listings for you</span>
        </h1>
        <div className="modcontent">
          <div id="so_listing_tabs_4" className="so-listing-tabs first-load">
            <div className="loadeding" />
            <div className="ltabs-wrap">
              <div
                className="row"
                style={{
                  textAlign: "center",
                  display: adverts.length !== 0 ? "none" : "block",
                }}
              >
                <div className="col-md-3">
                  <Skeleton style={{ height: 350, width: "100%" }} />
                </div>
                <div className="col-md-3">
                  <Skeleton style={{ height: 350, width: "100%" }} />
                </div>
                <div className="col-md-3">
                  <Skeleton style={{ height: 350, width: "100%" }} />
                </div>
                <div className="col-md-3">
                  <Skeleton style={{ height: 350, width: "100%" }} />
                </div>
              </div>

              <div className="wap-listing-tabs products-list grid">
                <div className="ltabs-items-container">
                  {/*Begin Items*/}
                  <div
                    className="ltabs-items ltabs-items-selected items-category-34"
                    data-total={16}
                  >
                    <div className="ltabs-items-inner myTabsInner">
                      {adverts.map((value, index) => {
                        return <ProductItem item={value} key={index} />;
                      })}
                    </div>
                    <div
                      className="ltabs-loadmore"
                      data-active-content=".items-category-31"
                      data-rl_start={12}
                      data-rl_total={12}
                      data-ajaxurl
                      data-rl_load={8}
                      data-moduleid={253}
                    >
                      <Link
                        to="/search"
                        className="ltabs-loadmore-btn loaded"
                        data-label="View All"
                        style={{ display: "inline-block" }}
                      ></Link>
                    </div>
                  </div>
                  <div
                    className="ltabs-items items-category-32 grid"
                    data-total={16}
                  >
                    <div className="ltabs-loading" />
                  </div>
                  <div
                    className="ltabs-items items-category-33 grid"
                    data-total={16}
                  >
                    <div className="ltabs-loading" />
                  </div>
                  {/*End Items*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingLayout1;

import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ImBin2, ImPencil } from "react-icons/im";
import { AppContext } from "../../context/AppContext";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withSwal } from "react-sweetalert2";

export default withSwal((props, ref) => {
  const { adverts, title, subtitle, swal, reload, user, loading, unfavourite } =
    props;
  const { formatNumber } = useContext(AppContext);
  const [swalProps, setSwalProps] = React.useState({});
  const navigate = useNavigate();
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        darkmode
      />
      <div className="title">
        <h1>
          {title} <span>{subtitle}</span>
        </h1>
      </div>
      <div className="main">
        <div className="row">
          {adverts?.data?.length == 0 || adverts.data == undefined ? (
            <img src="/image/empty.svg" className="empty" />
          ) : (
            adverts?.data?.map((value, index) => {
              return (
                <div className="col-md-6" key={index}>
                  <Link
                    to={"/advert/" + value?.advert?.id}
                    target="_self"
                    title={value?.advert?.title}
                  >
                    <div className="listing listing-small">
                      <div className="list-img-hold">
                        {value?.advert?.images[0] ? (
                          <img
                            src={value?.advert?.images[0]}
                            className="img-1 img-responsive"
                            alt="image1"
                          />
                        ) : (
                          <div className="imgBlack"></div>
                        )}
                      </div>
                      <div
                        className="text"
                        style={{ position: "relative", height: "100%" }}
                      >
                        <h2>{value?.advert?.title}</h2>
                        <p
                          className="condit"
                          style={{
                            position: "absolute",
                            bottom: 2,
                            textAlign: "left",
                            width: "100%",
                            justifyContent: "space-between",
                            display: "flex",
                            alignItems: "center",
                            padding: 10,
                            left: 0,
                          }}
                        >
                          <span>₦{formatNumber(value?.advert?.price)}</span>
                          <i
                            className="fa fa-heart"
                            style={{ color: "red" }}
                            onClick={(e) => {
                              e.preventDefault();
                              unfavourite(value?.advert?.id);
                            }}
                          ></i>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      </div>

      <div className="bottom">
        <p>
          Showing {adverts.from}-{adverts.to} of {adverts.total}
        </p>
        <div>
          <button type="button" className="btn btn-outline-primary">
            Prev
          </button>
          <button type="button" className="btn btn-outline-primary">
            Next
          </button>
        </div>
      </div>
    </div>
  );
});

import React from "react";

function Banners(props) {
  return (
    <div className="row-banners">
      <div className="banners banners1">
        <div className="item item1">
          <a href="#">
            <img src="image/catalog/banners/id5-bn1.jpg" alt="banner" />
          </a>
        </div>
        <div className="item item2">
          <a href="#">
            <img src="image/catalog/banners/id5-bn2.jpg" alt="banner" />
          </a>
        </div>
        <div className="item item3">
          <a href="#">
            <img src="image/catalog/banners/id5-bn3.jpg" alt="banner" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Banners;

import React, { useContext, useState } from "react";

import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { IoTrash } from "react-icons/io5";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Import the plugin code
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import { AppContext } from "../../context/AppContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Overlay from "../../components/Overlay";
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

function EditListing({ reload }) {
  const { categories, brands, conditions } = useContext(AppContext);
  const [files, setFiles] = React.useState([]);
  const params = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  /*** FORM INPUTS */
  const [title, setTitle] = React.useState();
  const [description, setDescription] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [condition, setCondition] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [brandModel, setBrandModel] = React.useState(0);
  const [brandModels, setBrandModels] = React.useState([]);

  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [display, setDisplay] = React.useState(1);
  const [advert, setAdvert] = useState("");
  const getBrandModels = (id) => {
    //const models = brands.filter((brand) => brand.id === id);
    var models = brands.filter(function (el) {
      return el.id == id;
    });
    setBrandModels(models[0]?.brand_models);
  };
  React.useEffect(() => {
    axios
      .get("https://dashboard.maineautoparts.com/api/adverts/" + params.edit_id)
      .then((response) => {
        let ad = response.data;
        setAdvert(ad);
        setTitle(ad.title);
        setDescription(ad.description);
        setCategory(ad.category.id);
        setPrice(ad.price);
        setBrand(ad.brand_id);
        setCondition(ad.condition_id);
        setAddress(ad.address.address);
        setDisplay(0);
        setFiles(ad.images);
        setBrandModel(ad.brandmodel_id);
      });
  }, [location]);
  React.useEffect(() => {
    getBrandModels(brand);
  }, [brand]);
  const submit = (e) => {
    e.preventDefault();
    //return false;
    setLoading(true);
    let user = localStorage.getItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    user = JSON.parse(user);
    const formData = new FormData();
    for (var i in files) {
      formData.append("images[]", files[i].file);
    }
    formData.append("title", title);
    formData.append("description", description);
    formData.append("category_id", category);
    formData.append("price", price);
    formData.append("address", address);
    formData.append("brand_id", brand);
    formData.append("brandmodel_id", brandModel);
    formData.append("condition_id", condition);
    formData.append("state", "nil");
    axios
      .post(
        "https://dashboard.maineautoparts.com/api/update-advert/" + advert.id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        setErrors([]);
        toast.success("Advert updated!", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        reload();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setErrors([]);
        } else {
          setErrors(error.response.data.errors);
        }
        toast.error("Error occured!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };
  React.useEffect(() => {
    setErrors([]);
  }, [params]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        darkmode
      />
      <div className="ImageUpload row">
        <div
          className={`
              col-md-12
              ${errors?.images ? " is-invalid" : ""}
              ${files.length != 0 ? "" : "addH"}`}
        >
          <FilePond
            files={files}
            onupdatefiles={(fileItems) => {
              /* fileItems.map((fileItem) => {
                console.log(fileItem.file);

                var _preview = {};
                var _reader = new FileReader();

                _reader.onloadend = function () {
                  _preview.src = _reader.result;
                  console.log(_preview.src);
                };

                if (fileItem) {
                  if (fileItem.file) {
                    _reader.readAsDataURL(fileItem.file);
                  }
                } else {
                  _preview.src = "";
                }
              });*/
              setFiles(fileItems);
            }}
            allowMultiple={true}
            allowReorder={true}
            maxFiles={3}
            maxFileSize="1MB"
            instantUpload={false}
            allowProcess={false}
            storeAsFile={true}
            allowFileTypeValidation={true}
            allowFileSizeValidation={true}
            acceptedFileTypes={["image/*"]}
            name="files"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
        </div>
        <div className="col-md-12">
          <input
            name="title"
            type="title"
            className={`
              overide input-select
              ${errors?.title ? " is-invalid" : ""}`}
            placeholder="Title"
            defaultValue={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <div className="col-md-12">
          <textarea
            name="description"
            type="description"
            className={`
              overide input-select
              ${errors?.description ? " is-invalid" : ""}`}
            placeholder="Description"
            defaultValue={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>
        <div className="col-md-6">
          <select
            className={`
              overide input-select
              ${errors?.category_id ? " is-invalid" : ""}`}
            onChange={(event) => setCategory(event.target.value)}
            value={category}
          >
            <option value="">--Select Category--</option>
            {categories?.map((value, index) => {
              return (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <select
            className={`
              overide input-select
              ${errors?.brand_id ? " is-invalid" : ""}`}
            onChange={(event) => {
              let obj = event.target.value;
              setBrand(obj);
              getBrandModels(obj);
            }}
            value={brand}
          >
            <option value="">--Select Brand--</option>
            {brands?.map((value, index) => {
              return (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <select
            className={`
              overide input-select
              ${errors?.brandmodel_id ? " is-invalid" : ""}`}
            onChange={(event) => {
              setBrandModel(event.target.value);
            }}
            value={brandModel}
          >
            <option value="">--Select Brand Model</option>
            {brand === "" ? (
              <option value="">Select brand first</option>
            ) : (
              <></>
            )}
            {brandModels?.map((value, index) => {
              return (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <select
            className={`
              overide input-select
              ${errors?.condition_id ? " is-invalid" : ""}`}
            value={condition}
            onChange={(event) => {
              setCondition(event.target.value);
            }}
          >
            <option value="">--Select Condition</option>=
            {conditions?.map((value, index) => {
              return (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <input
            name="price"
            type="number"
            className={`
              overide input-select
              ${errors?.price ? " is-invalid" : ""}`}
            placeholder="Price"
            defaultValue={price}
            onChange={(event) => setPrice(event.target.value)}
          />
        </div>
        <div className="col-md-6">
          <input
            name="address"
            type="text"
            className={`
              overide input-select
              ${errors?.address ? " is-invalid" : ""}`}
            placeholder="address"
            defaultValue={address}
            onChange={(event) => setAddress(event.target.value)}
          />
        </div>
        <div className="col-md-6">
          <button type="submit" className="sign-up-btn" onClick={submit}>
            Update
            <span
              className="spinner-border spinner-border-sm onPageloader"
              role="status"
              aria-hidden="true"
              style={{ display: loading ? "inline-block" : "none" }}
            ></span>
          </button>
        </div>
      </div>
      <Overlay display={display} />
    </div>
  );
}

export default EditListing;

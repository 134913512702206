import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SmallProductItem from "../SmallProductItem";
import CatSearch from "./CatSearch";

function CatScreenSideBar({ latests }) {
  return (
    <aside className="col-sm-4 col-md-3 content-aside" id="column-left">
      <CatSearch />
      <div className="module product-simple">
        <h3 className="modtitle">
          <span>LATEST ADVERTS</span>
        </h3>
        <div className="modcontent">
          <div id="so_extra_slider_1" className="so-extraslider">
            {/* Begin extraslider-inner */}
            <div className="extraslider-inner">
              <div className="item ">
                <div
                  className="row"
                  style={{
                    textAlign: "left",
                    display: latests.length !== 0 ? "none" : "block",
                    marginTop: 10,
                  }}
                >
                  <div className="col-md-12">
                    <Skeleton style={{ height: 20, width: "100%" }} />
                    <Skeleton style={{ height: 60, width: "100%" }} />
                  </div>
                  <div className="col-md-12" style={{ marginTop: 20 }}>
                    <Skeleton style={{ height: 20, width: "100%" }} />
                    <Skeleton style={{ height: 60, width: "100%" }} />
                  </div>
                  <div className="col-md-12" style={{ marginTop: 20 }}>
                    <Skeleton style={{ height: 20, width: "100%" }} />
                    <Skeleton style={{ height: 60, width: "100%" }} />
                  </div>
                </div>
                {latests.map((value, index) => {
                  return <SmallProductItem value={value} key={index} />;
                })}
              </div>
            </div>
            {/*End extraslider-inner */}
          </div>
        </div>
      </div>
    </aside>
  );
}

export default CatScreenSideBar;

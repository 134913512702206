import axios from "axios";
import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

function ForgotPassword(props) {
  const params = useParams();
  let navigate = useNavigate();
  const [Email, setEmail] = React.useState("yei@primebytes.ca");
  const [Success, setSuccess] = React.useState(false);
  const [EmailError, setEmailError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("email", Email);
    axios
      .post(
        "https://dashboard.maineautoparts.com/api/password/email",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setEmailError(false);
        setSuccess(response.data[1]);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
        setEmailError(error.response.data.email);
      });
  };
  return (
    <div className="container mb-100">
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6">
          <div className="card-sign-up-2">
            <h3 className="form-title">Forgot Password</h3>

            <p style={{ color: "red", textAlign: "center" }} />
            <form action="#" method="post">
              <input
                name="email"
                type="email"
                className={[
                  "overide input-email ",
                  EmailError ? " is-invalid" : "",
                ]}
                placeholder="Email address e.g john@gmail.com"
                defaultValue={Email}
                onChange={(event) => setEmail(event.target.value)}
              />

              {EmailError ? <p className="error">{EmailError}</p> : ""}
              {Success ? <p className="success">{Success}</p> : ""}
              <button type="submit" className="sign-up-btn" onClick={submit}>
                Reset my password
                <span
                  className="spinner-border spinner-border-sm onPageloader"
                  role="status"
                  aria-hidden="true"
                  style={{ display: loading ? "inline-block" : "none" }}
                ></span>
              </button>

              <p>
                Enter the email address associated with your account and we will
                send you a link to reset your password.
              </p>
            </form>
          </div>
          <p className="terms-form">
            Read our{" "}
            <span className="tt">
              <Link className="aa-colo-2" to="/terms-of-services">
                Terms of service{" "}
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

import React from "react";

function Filter({ setGrid, setList }) {
  const [active, setActive] = React.useState(1);
  return (
    <div className="product-filter product-filter-top filters-panel">
      <div className="row">
        <div className="col-md-5 col-sm-3 col-xs-12 view-mode">
          <div className="list-view">
            <button
              className={`btn btn-default grid ${active == 1 ? "active" : ""}`}
              data-view="grid"
              data-toggle="tooltip"
              data-original-title="Grid"
              onClick={() => {
                setGrid();
                setActive(1);
              }}
            >
              <i className="fa fa-th" />
            </button>
            <button
              className={`btn btn-default list ${active == 2 ? "active" : ""}`}
              data-view="list"
              data-toggle="tooltip"
              data-original-title="List"
              onClick={() => {
                setList();
                setActive(2);
              }}
            >
              <i className="fa fa-th-list" />
            </button>
          </div>
        </div>
        {/*--
        <div className="short-by-show form-inline text-right col-md-7 col-sm-9 col-xs-12">
          <div className="form-group short-by">
            <label className="control-label" htmlFor="input-sort">
              Sort By:
            </label>
            <select
              id="input-sort"
              className="form-control"
              defaultValue="Default"
            >
              <option value="default">Default</option>
              <option>Name (A - Z)</option>
              <option>Name (Z - A)</option>
              <option>Price (Low &gt; High)</option>
              <option>Price (High &gt; Low)</option>
              <option>Rating (Highest)</option>
              <option>Rating (Lowest)</option>
              <option>Model (A - Z)</option>
              <option>Model (Z - A)</option>
            </select>
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="input-limit">
              Show:
            </label>
            <select id="input-limit" className="form-control" defaultValue={15}>
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        --*/}
      </div>
    </div>
  );
}

export default Filter;

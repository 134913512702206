import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

function CatSearch(props) {
  const { categories, brands, conditions, searchAds } = useContext(AppContext);
  let navigate = useNavigate();

  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [condition, setCondition] = React.useState("");
  const [minPrice, setMinPrice] = React.useState("");
  const [maxPrice, setMaxPrice] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [brandModel, setBrandModel] = React.useState([]);
  const [brandModels, setBrandModels] = React.useState([]);

  return (
    <>
      <div className="module">
        <h3 className="modtitle">Filter </h3>
        <div className="modcontent ">
          <form className="type_2">
            <div className="table_layout filter-shopby">
              <div className="table_row">
                {/* - - - - - - - - - - - - - Category filter - - - - - - - - - - - - - - - - */}
                <div className="table_cell" style={{ zIndex: 103 }}>
                  <legend>Search</legend>
                  <input
                    className="form-control"
                    placeholder="Item name e.g fuel pump"
                    onChange={(event) => setTitle(event.target.value)}
                  />
                </div>
                {/*/ .table_cell */}
                {/* - - - - - - - - - - - - - End of category filter - - - - - - - - - - - - - - - - */}
                {/* - - - - - - - - - - - - - SUB CATEGORY - - - - - - - - - - - - - - - - */}
                <div className="table_cell">
                  <fieldset>
                    <legend>Category</legend>
                    <select
                      className="form-control"
                      onChange={(event) => setCategory(event.target.value)}
                    >
                      <option value="">--Select Category--</option>
                      {categories?.map((value, index) => {
                        return (
                          <option key={index} value={value.id}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </fieldset>
                </div>
                {/*/ .table_cell */}
                {/* - - - - - - - - - - - - - End CATEGORY - - - - - - - - - - - - - - - - */}
                {/* - - - - - - - - - - - - - Brand - - - - - - - - - - - - - - - - */}
                <div className="table_cell">
                  <fieldset>
                    <legend>Brand</legend>
                    <select
                      className="form-control"
                      id="so_make0"
                      onChange={(event) => {
                        let obj = JSON.parse(event.target.value);
                        setBrand(obj.id);
                        setBrandModels(obj.brand_models);
                      }}
                    >
                      <option value="">--Select Brand--</option>
                      {brands?.map((value, index) => {
                        return (
                          <option key={index} value={JSON.stringify(value)}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </fieldset>
                </div>
                {/*/ .table_cell */}
                {/* - - - - - - - - - - - - - End Brand - - - - - - - - - - - - - - - - */}

                {/* - - - - - - - - - - - - - Brand - - - - - - - - - - - - - - - - */}
                {brand != "" ? (
                  <div className="table_cell">
                    <fieldset>
                      <legend>Brand Model</legend>
                      <select
                        className="form-control"
                        onChange={(event) => {
                          setBrandModel(event.target.value);
                        }}
                      >
                        <option value="">--Select Brand Model</option>
                        {brand === "" ? (
                          <option value="">Select brand first</option>
                        ) : (
                          <></>
                        )}
                        {brandModels?.map((value, index) => {
                          return (
                            <option key={index} value={value.id}>
                              {value.name}
                            </option>
                          );
                        })}
                      </select>
                    </fieldset>
                  </div>
                ) : (
                  <></>
                )}
                {/*/ .table_cell */}
                {/* - - - - - - - - - - - - - End Brand - - - - - - - - - - - - - - - - */}
                {/* - - - - - - - - - - - - - Condition - - - - - - - - - - - - - - - - */}
                <div className="table_cell">
                  <fieldset>
                    <legend>Condition</legend>
                    <select
                      className="form-control"
                      value={condition}
                      onChange={(event) => {
                        setCondition(event.target.value);
                      }}
                    >
                      <option value="">--Select Condition</option>=
                      {conditions?.map((value, index) => {
                        return (
                          <option key={index} value={value.id}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </fieldset>
                </div>
                {/*/ .table_cell */}
                {/* - - - - - - - - - - - - - End Condition - - - - - - - - - - - - - - - - */}
                {/* - - - - - - - - - - - - - Price - - - - - - - - - - - - - - - - */}
                <div className="table_cell">
                  <fieldset>
                    <legend>Price</legend>
                    <div className="flexBox">
                      <div className="search-boxes" style={{ width: "49%" }}>
                        <input
                          className="form-control"
                          placeholder="Min price"
                          onChange={(event) => setMinPrice(event.target.value)}
                        />
                      </div>

                      <div className="search-boxes" style={{ width: "49%" }}>
                        <input
                          className="form-control"
                          placeholder="Max price"
                          onChange={(event) => setMaxPrice(event.target.value)}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
                {/*/ .table_cell */}
                {/* - - - - - - - - - - - - - End price - - - - - - - - - - - - - - - - */}
                {/* - - - - - - - - - - - - - Price - - - - - - - - - - - - - - - - */}

                {/*/ .table_cell */}
                {/* - - - - - - - - - - - - - End price - - - - - - - - - - - - - - - - */}
              </div>
              {/*/ .table_row */}
              <footer className="bottom_box">
                <button
                  type="button"
                  id="sas_search_button0"
                  className="button_grey button_submit"
                  onClick={() => {
                    searchAds(category, brand, minPrice, maxPrice, title);
                    navigate("../search", { replace: true });
                  }}
                  style={{ width: "100%" }}
                >
                  Find My Part
                </button>
                {/*Back To Top*/}
                <div className="back-to-top">
                  <i className="fa fa-angle-up" />
                </div>
              </footer>
            </div>
            {/*/ .table_layout */}
          </form>
        </div>
      </div>
    </>
  );
}

export default CatSearch;

import axios from "axios";
import React, { createContext } from "react";

export const AppContext = createContext();
export const AppProvider = ({ children }) => {
  const [categories, setCategories] = React.useState([]);
  const [favourites, setFavourites] = React.useState([]);
  const [topCategories, setTopCategories] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [conditions, setConditions] = React.useState([]);
  const [searchedAdverts, setSearchedAdverts] = React.useState([]);
  const [display, setdisplay] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [reset, setReset] = React.useState(false);
  const [searchtitle, setsearchtitle] = React.useState("");
  React.useEffect(() => {
    const reset = () => {
      setsearchtitle("");
      setSearchedAdverts([]);
    };
    reset();
  }, [reset]);
  React.useEffect(() => {
    axios
      .get("https://dashboard.maineautoparts.com/api/categories")
      .then((response) => {
        let cat = categories;
        let cat_array = topCategories;
        /*response.data.forEach((category) => {
          if (category.adverts.length > 0) {
            cat.push(category);
            setCategories(cat);
          }
          if (category.count > 3 && category.adverts.length > 1) {
            category.adverts = category.adverts.slice(0, 4);
            cat_array.push(category);
            setTopCategories(cat_array);
          }
        });
        cat_array = cat_array.slice(0, 3);*/
        cat_array = response.data.slice(0, 3);
        setCategories(response.data);
        setTopCategories(cat_array);
      });
    axios
      .get("https://dashboard.maineautoparts.com/api/brands")
      .then((response) => {
        setBrands(response.data.data);
      });
    axios
      .get("https://dashboard.maineautoparts.com/api/conditions")
      .then((response) => {
        setConditions(response.data.data);
      });
    const getFavourites = () => {
      let user = localStorage.getItem(
        "xompsmpsnd101010299msiknsloa11292nsinsi"
      );
      user = JSON.parse(user);
      if (user == null) {
        return false;
      }
      axios({
        method: "get",
        url: "https://dashboard.maineautoparts.com/api/allFavourites",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }).then((response) => {
        let ads = response.data.data;
        setFavourites(ads);
      });
    };
    getFavourites();
  }, []);
  const searchAds = (category, brand, minPrice, maxPrice, title) => {
    setdisplay(true);
    axios
      .get(
        `https://dashboard.maineautoparts.com/api/search-adverts?featured=0&category_id=${category}&brand_id=${brand}&dealer_id&minimumPrice=${minPrice}&maximumPrice=${maxPrice}&search=${title}`
      )
      .then((response) => {
        let ads = response.data.data;
        setSearchedAdverts(ads);
        setdisplay(false);
        setsearchtitle(title);
      });
  };
  const formatNumber = (num) => {
    if (num != undefined) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  };
  const favourite = (id) => {
    let user = localStorage.getItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    user = JSON.parse(user);
    if (user == null) {
      return false;
    }
    axios
      .put(
        "https://dashboard.maineautoparts.com/api/favourite/" + id,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      )
      .then(() => {
        getFavourites();
      })
      .catch((error) => console.log(error));
  };
  const getFavourites = () => {
    let user = localStorage.getItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    user = JSON.parse(user);
    if (user == null) {
      return false;
    }
    axios({
      method: "get",
      url: "https://dashboard.maineautoparts.com/api/allFavourites",
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    }).then((response) => {
      let ads = response.data.data;
      setFavourites(ads);
    });
  };

  return (
    <AppContext.Provider
      value={{
        categories,
        topCategories,
        brands,
        conditions,
        searchAds,
        searchedAdverts,
        formatNumber,
        favourite,
        favourites,
        getFavourites,
        display,
        searchtitle,
        setReset,
        reset,
        reload,
        setReload,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

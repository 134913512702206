import axios from "axios";
import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

function Login(props) {
  const params = useParams();
  let navigate = useNavigate();
  const [Email, setEmail] = React.useState("");
  const [Password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("password", Password);
    formData.append("email", Email);
    formData.append("is_company", 1);
    axios
      .post("https://dashboard.maineautoparts.com/api/login", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading(false);
        setErrors([]);
        const user = JSON.stringify(response.data);
        localStorage.setItem("xompsmpsnd101010299msiknsloa11292nsinsi", user);
        navigate("../dashboard", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        setInvalid(true);
        if (error.response.status === 401) {
          setErrors([]);
        } else {
          setErrors(error.response.data.errors);
        }
      });
  };
  React.useEffect(() => {
    setErrors([]);
  }, [params]);
  return (
    <div className="container mb-100">
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6">
          <div className="card-sign-up-2">
            <h3 className="form-title">Sign into an account</h3>

            <p style={{ color: "red", textAlign: "center" }} />
            <form action="#" method="post">
              <input
                name="email"
                type="email"
                className={[
                  "overide input-email ",
                  errors?.email || invalid ? " is-invalid" : "",
                ]}
                placeholder="Email address e.g john@gmail.com"
                defaultValue={Email}
                onChange={(event) => setEmail(event.target.value)}
              />
              {errors?.email != undefined ? (
                <p className="error">{errors?.email[0]}</p>
              ) : (
                ""
              )}
              <input
                name="password"
                type="password"
                placeholder="Choose password"
                defaultValue={Password}
                className={[
                  "overide input-password ",
                  errors?.password || invalid ? " is-invalid" : "",
                ]}
                onChange={(event) => setPassword(event.target.value)}
              />
              {errors?.password != undefined ? (
                <p className="error">{errors?.password[0]}</p>
              ) : (
                ""
              )}

              {invalid ? <p className="error">Invalid credentials</p> : ""}

              <p className="fgp">
                <Link className="forgot-link" to="/forgot_password">
                  Forgot password?
                </Link>
              </p>
              <button type="submit" className="sign-up-btn" onClick={submit}>
                Log me in
                <span
                  className="spinner-border spinner-border-sm onPageloader"
                  role="status"
                  aria-hidden="true"
                  style={{ display: loading ? "inline-block" : "none" }}
                ></span>
              </button>
              <h3 className="form-title">
                Don't have an account?
                <Link className="sign-link" to="/register">
                  Sign Up
                </Link>
              </h3>
            </form>
          </div>
          <p className="terms-form">
            Read our{" "}
            <span className="tt">
              <Link className="aa-colo-2" to="/terms-of-services">
                Terms of service{" "}
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { toSeoUrl } from "../constants";

function SmallProductItem({ value }) {
  const { formatNumber } = useContext(AppContext);
  return (
    <Link
      to={"/advert/" + toSeoUrl(value.title) + "/" + value.id}
      target="_self"
      className="product-layout item-inner style1 "
    >
      <div className="item-image">
        <div className="item-img-info">
          <div className="side-img-hold">
            <img src={value.images[0]} alt={value.title} />
          </div>
        </div>
      </div>
      <div className="item-info">
        <p className="item-title" style={{ fontSize: 13, lineHeight: "18px" }}>
          {value.title}
        </p>
      </div>
      <div className="content_price price" style={{ left: 105 }}>
        <span className="price-new product-price" style={{ fontSize: 13 }}>
          ₦{formatNumber(value.price)}
        </span>
        &nbsp;&nbsp;
      </div>
    </Link>
  );
}

export default SmallProductItem;

import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Enquiries({ adverts, title, subtitle }) {
  const navigate = useNavigate();
  const [single, setsingle] = React.useState("");

  return (
    <div>
      <div className="title">
        <h1>{title}</h1>
        {single != "" ? (
          <img
            role="button"
            src="/image/back.svg"
            alt=""
            className="img-fluid"
            onClick={() => setsingle("")}
          />
        ) : (
          <></>
        )}
      </div>
      {single == "" ? (
        <>
          <div className="main">
            <div className="row">
              {adverts?.data?.length == 0 ? (
                <img src="/image/empty.svg" className="empty" />
              ) : (
                adverts?.data?.map((value, index) => {
                  return (
                    <div
                      className="col-md-6"
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        setsingle(value);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="listing listing2">
                        <div className="list-img-hold">
                          <img
                            src={value.images[0]}
                            className="img-1 img-responsive"
                            alt="image1"
                            style={{
                              display:
                                value.images[0] != undefined ? "block" : "none",
                            }}
                          />
                          <div
                            src={value.images[0]}
                            className="img-pl"
                            style={{
                              display:
                                value.images[0] == undefined ? "block" : "none",
                            }}
                          ></div>
                        </div>
                        <div className="text">
                          <h2>{value.title}</h2>
                          <h3
                            style={{
                              display:
                                value.name != undefined ? "block" : "none",
                            }}
                          >
                            {value.name}
                          </h3>
                          <p>{value.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div className="bottom">
            <p>
              Showing {adverts.from}-{adverts.to} of {adverts.total}
            </p>
            <div>
              <button type="button" className="btn btn-outline-primary">
                Prev
              </button>
              <button type="button" className="btn btn-outline-primary">
                Next
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-md-12" style={{ cursor: "pointer" }}>
            <div className="listing listingBig">
              <div className="list-img-hold">
                <img
                  src={single.images[0]}
                  className="img-1 img-responsive"
                  alt="image1"
                  style={{
                    display: single.images[0] != undefined ? "block" : "none",
                  }}
                />
                <div
                  src={single.images[0]}
                  className="img-pl"
                  style={{
                    display: single.images[0] == undefined ? "block" : "none",
                  }}
                ></div>
              </div>
              <div className="text">
                <h2>{single.title}</h2>
                <h3
                  style={{
                    display: single.name != undefined ? "block" : "none",
                  }}
                >
                  {single.name}
                </h3>
                <p>{single.description}</p>
                <p>{single.phone}</p>
              </div>
            </div>
          </div>
        </>
      )}

      <br></br>
      <Link to="/part-request" className="request-a">
        Want to make a request?
      </Link>
    </div>
  );
}

export default Enquiries;

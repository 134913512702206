import React, { useContext } from "react";

import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { IoTrash } from "react-icons/io5";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Import the plugin code
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import { AppContext } from "../../context/AppContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import states from "../../data/states";
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

function CreateListing({ reload }) {
  const [files, setFiles] = React.useState([]);
  const params = useParams();
  let navigate = useNavigate();
  /*** FORM INPUTS */
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [condition, setCondition] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [state, setState] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [brandModel, setBrandModel] = React.useState([]);
  const [brandModels, setBrandModels] = React.useState([]);

  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const { categories, brands, conditions } = useContext(AppContext);
  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    let user = localStorage.getItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    user = JSON.parse(user);
    const formData = new FormData();
    for (var i in files) {
      formData.append("images[]", files[i].file);
    }
    formData.append("title", title);
    formData.append("description", description);
    formData.append("category_id", category);
    formData.append("price", price);
    formData.append("address", address);
    formData.append("brand_id", brand);
    formData.append("brandmodel_id", brandModel);
    formData.append("condition_id", condition);
    formData.append("state", state);
    axios
      .post(
        "https://dashboard.maineautoparts.com/api/create-advert",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      )
      .then(() => {
        setErrors([]);
        toast.success("Advert created! Now pending approval.", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        reload();
        setTimeout(() => {
          navigate("/inactive-listing", { replace: true });
          setLoading(false);
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setErrors([]);
        } else {
          setErrors(error.response.data.errors);
        }
        toast.error("Error occured!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };
  React.useEffect(() => {
    setErrors([]);
  }, [params]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        darkmode
      />
      <div className="ImageUpload row">
        <div
          className={`
              col-md-12
              ${errors?.images ? " is-invalid" : ""}
              ${files.length != 0 ? "" : "addH"}`}
        >
          <FilePond
            files={files}
            onupdatefiles={(fileItems) => {
              /* fileItems.map((fileItem) => {
                console.log(fileItem.file);

                var _preview = {};
                var _reader = new FileReader();

                _reader.onloadend = function () {
                  _preview.src = _reader.result;
                  console.log(_preview.src);
                };

                if (fileItem) {
                  if (fileItem.file) {
                    _reader.readAsDataURL(fileItem.file);
                  }
                } else {
                  _preview.src = "";
                }
              });*/
              setFiles(fileItems);
            }}
            allowMultiple={true}
            allowReorder={true}
            maxFiles={3}
            maxFileSize="2MB"
            instantUpload={false}
            allowProcess={false}
            storeAsFile={true}
            allowFileTypeValidation={true}
            allowFileSizeValidation={true}
            acceptedFileTypes={["image/*"]}
            //server="/api"
            name="files"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
          <span className="error">{errors?.images}</span>
        </div>
        <div className="col-md-6">
          <input
            name="title"
            type="title"
            className={`
              overide input-select
              ${errors?.title ? " is-invalid" : ""}`}
            placeholder="Title"
            defaultValue={title}
            onChange={(event) => setTitle(event.target.value)}
          />
          <span className="error">{errors?.title}</span>
        </div>
        <div className="col-md-6">
          <select
            className={`
              overide input-select
              ${errors?.category_id ? " is-invalid" : ""}`}
            onChange={(event) => setCategory(event.target.value)}
          >
            <option value="">--Select Category--</option>
            {categories?.map((value, index) => {
              return (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              );
            })}
          </select>
          <span className="error">{errors?.category}</span>
        </div>
        <div className="col-md-6">
          <select
            className={`
              overide input-select
              ${errors?.brand_id ? " is-invalid" : ""}`}
            onChange={(event) => {
              let obj = JSON.parse(event.target.value);
              setBrand(obj.id);
              setBrandModels(obj.brand_models);
            }}
          >
            <option value="">--Select Brand--</option>
            {brands?.map((value, index) => {
              return (
                <option key={index} value={JSON.stringify(value)}>
                  {value.name}
                </option>
              );
            })}
          </select>
          <span className="error">{errors?.brand_id}</span>
        </div>
        <div className="col-md-6">
          <select
            className={`
              overide input-select
              ${errors?.brandmodel_id ? " is-invalid" : ""}`}
            onChange={(event) => {
              setBrandModel(event.target.value);
            }}
          >
            <option value="">--Select Brand Model</option>
            {brand === "" ? (
              <option value="">Select brand first</option>
            ) : (
              <></>
            )}
            {brandModels?.map((value, index) => {
              return (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              );
            })}
          </select>
          <span className="error">{errors?.brandmodel_id}</span>
        </div>
        <div className="col-md-6">
          <select
            className={`
              overide input-select
              ${errors?.condition_id ? " is-invalid" : ""}`}
            value={condition}
            onChange={(event) => {
              setCondition(event.target.value);
            }}
          >
            <option value="">--Select Condition</option>=
            {conditions?.map((value, index) => {
              return (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              );
            })}
          </select>
          <span className="error">{errors?.condition}</span>
        </div>
        <div className="col-md-6">
          <input
            name="price"
            type="number"
            className={`
              overide input-select
              ${errors?.price ? " is-invalid" : ""}`}
            placeholder="Price"
            defaultValue={price}
            onChange={(event) => setPrice(event.target.value)}
          />
          <span className="error">{errors?.price}</span>
        </div>
        <div className="col-md-6">
          <input
            name="address"
            type="text"
            className={`
              overide input-select
              ${errors?.address ? " is-invalid" : ""}`}
            placeholder="Address"
            defaultValue={address}
            onChange={(event) => setAddress(event.target.value)}
          />
          <span className="error">{errors?.address}</span>
        </div>
        <div className="col-md-6">
          <select
            className={`
              overide input-select
              ${errors?.state ? " is-invalid" : ""}`}
            onChange={(event) => setState(event.target.value)}
          >
            <option value="">--Select State--</option>
            {states?.map((value, index) => {
              return (
                <option key={index} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
          <span className="error">{errors?.state}</span>
        </div>
        <div className="col-md-12">
          <textarea
            name="description"
            type="description"
            className={`
              overide input-select
              ${errors?.description ? " is-invalid" : ""}`}
            placeholder="Description"
            defaultValue={description}
            onChange={(event) => setDescription(event.target.value)}
          />
          <span className="error">{errors?.description}</span>
        </div>
        <div className="col-md-6">
          <button type="submit" className="sign-up-btn" onClick={submit}>
            Create Ad
            <span
              className="spinner-border spinner-border-sm onPageloader"
              role="status"
              aria-hidden="true"
              style={{ display: loading ? "inline-block" : "none" }}
            ></span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateListing;

import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { ImBin2, ImPencil } from "react-icons/im";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import Overlay from "../../components/Overlay";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withSwal } from "react-sweetalert2";

export default withSwal((props, ref) => {
  const { subtitle, swal, reload } = props;
  const { formatNumber } = useContext(AppContext);
  const [advert, setAdvert] = useState("");
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();
  const [display, setDisplay] = React.useState(1);
  const deleteAdvert = (id) => {
    let user = localStorage.getItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    user = JSON.parse(user);
    swal
      .fire({
        show: true,
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: "delete!",
        preConfirm: () => {
          return fetch(
            `https://dashboard.maineautoparts.com/api/advert/${id}`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${user.accessToken}`,
              },
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              return response.json();
            })
            .catch((error) => {
              swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then((result) => {
        if (result.isConfirmed) {
          reload();
          navigate("../all-listing", { replace: true });
        }
      });
  };
  React.useEffect(() => {
    axios
      .get("https://dashboard.maineautoparts.com/api/adverts/" + params.item_id)
      .then((response) => {
        let ad = response.data;
        setAdvert(ad);
        setDisplay(0);
      });
  }, [location]);

  return (
    <div>
      <div className="title">
        <h1>
          {advert?.title} <span>{subtitle}</span>
        </h1>
        <img
          role="button"
          src="/image/back.svg"
          alt=""
          className="img-fluid"
          onClick={() => navigate(-1)}
        />
      </div>
      <div className="full-width">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <div className="full-width">
                <div className="row">
                  {advert?.images?.map((value, index) => {
                    return (
                      <div className="col-6 col-md-4">
                        <img
                          className="img-fluid dt-img"
                          itemProp="image"
                          src={value}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="line" />
                <div className="row">
                  <div className="col-6 col-md-4">
                    <h3 className="dt-tit">
                      ITEM CONDITION <br />
                      <span className="dt-info">{advert?.condition?.name}</span>
                    </h3>
                  </div>
                  <div className="col-6 col-md-4">
                    <h3 className="dt-tit">
                      CATEGORY <br />
                      <span className="dt-info">{advert?.category?.name}</span>
                    </h3>
                  </div>
                  <div className="col-6 col-md-4">
                    <h3 className="dt-tit">
                      BRAND MODEL <br />
                      <span className="dt-info">
                        {advert?.brandmodel?.name}
                      </span>
                    </h3>
                  </div>
                  <div className="col-6 col-md-4">
                    <h3 className="dt-tit">
                      STATUS <br />
                      <span>
                        <img
                          src="/image/check.svg"
                          alt="Image test"
                          className="img-fluid checo"
                        />
                      </span>
                    </h3>
                  </div>
                  <div className="col-6 col-md-4">
                    <h3 className="dt-tit">
                      PRICE <br />
                      <span className="dt-info">
                        ₦{formatNumber(advert?.price)}
                      </span>
                    </h3>
                  </div>
                  <div className="col-6 col-md-4">
                    <h3 className="dt-tit">
                      SHARE ADS <br />
                      <a
                        href={`"https://api.whatsapp.com/send?phone=&text= Hey, can you check out this ad on maine-autopart, here's is the link https://www.maineautoparts.com/ads/"${params.item_id}`}
                        rel="noreferrer noopener"
                      >
                        <img
                          src="/image/share.svg"
                          className="img-fluid share-icon"
                          alt="share"
                        />
                      </a>
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="dt-tit">
                      ITEM LOCATION <br />
                      <span className="dt-info">
                        {advert?.address != null &&
                          `${advert?.address?.address}`}
                        {advert?.address?.city != null &&
                          `, ${advert?.address?.city}`}
                        {advert?.address?.state != null &&
                          `, ${advert?.address?.state}.`}
                      </span>
                    </h3>
                  </div>
                  <div className="col-lg-12">
                    <h3 className="dt-tit">
                      DESCRIPTION <br /> <span className="dt-info" />
                    </h3>
                    <p>{advert?.description}</p>
                  </div>
                </div>
                <div className="padd-card" />
              </div>
            </div>
          </div>
        </div>
        <div className="bottom buttons pd-none">
          <Link to={"/dashboard/edit-advert/" + advert.id}>
            <button className="edit-btn">
              <ImPencil /> Edit
            </button>
          </Link>
          <button
            className="del-btn"
            onClick={(e) => {
              e.preventDefault();
              deleteAdvert(advert.id);
            }}
          >
            <ImBin2 /> Delete
          </button>
        </div>
      </div>
      <Overlay display={display} />
    </div>
  );
});

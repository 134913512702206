import React from "react";
import Home from "./pages/Home";
import Category from "./pages/Category";
import Product from "./pages/Product";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Header from "./pages/Layout/Header";
import Register from "./pages/Auth/Register";
import Verify from "./pages/Auth/Verify";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Search from "./pages/Search";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Terms from "./pages/Terms";
import Faq from "./pages/Faq";
import About from "./pages/About";
import Request from "./pages/Request";
import Contact from "./pages/Contact";
import Career from "./pages/Career";
import ResetPassword from "./pages/Auth/ResetPassword";
import DealerAdverts from "./pages/DealerAdverts";
import Remove from "./pages/Remove";
import Qr from "./pages/Qr";
function App(props) {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <BrowserRouter>
      <Header categories />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category/:category/:id" element={<Category />} />
        <Route
          path="/dealer-adverts/:dealer_id/:dealer_name"
          element={<DealerAdverts />}
        />
        <Route path="/advert/:id" element={<Product />} />
        <Route path="/advert/:title/:id" element={<Product />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/password_reset/:token" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/active-listing" element={<Dashboard />} />
        <Route path="/inactive-listing" element={<Dashboard />} />
        <Route path="/all-listing" element={<Dashboard />} />
        <Route path="/wishlist" element={<Dashboard />} />
        <Route path="/create-advert" element={<Dashboard />} />
        <Route path="/part-requests" element={<Dashboard />} />
        <Route path="/listing" element={<Dashboard />} />
        <Route path="/update-profile" element={<Dashboard />} />
        <Route path="/dashboard/advert/:item_id" element={<Dashboard />} />
        <Route path="/dashboard/enquiry/:enquiry" element={<Dashboard />} />
        <Route path="/dashboard/edit-advert/:edit_id" element={<Dashboard />} />
        <Route path="/search" element={<Search />} />
        <Route path="/terms-of-services" element={<Terms />} />
        <Route path="/privacy-policy" element={<Terms />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<About />} />
        <Route path="/part-request" element={<Request />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/request" element={<Request />} />
        <Route path="/remove_data" element={<Remove />} />
        <Route path="/verify/:token" element={<Verify />} />
        <Route path="/app_redirect" element={<Qr />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { toSeoUrl } from "../constants";
function ProductItem({ item, img2 }) {
  const { formatNumber, favourite, favourites, getFavourites } =
    useContext(AppContext);
  const setFavourite = (id) => {
    item.isFavourite = !item.isFavourite;
    favourite(id);
  };
  React.useEffect(() => {
    let isFavourite = favourites;
  }, []);
  return (
    <Link
      to={"/advert/" + toSeoUrl(item.title) + "/" + item.id}
      className={`product-item-container item--static ${img2 ? "min-3" : ""}`}
      style={{ display: "block" }}
    >
      <div className="left-block">
        <div className="product-image-container second_img">
          <div
            title={item.title}
            className={`img-hold ${img2 ? "img-hold2" : ""}`}
          >
            <img
              src={item.images[0]}
              className="img-1 img-responsive"
              alt="image1"
            />
            <img
              src={
                item.images[1] != undefined ? item.images[1] : item.images[0]
              }
              className="img-2 img-responsive"
              alt="image2"
            />
          </div>
        </div>
        {/*quickview*/}
        <div className="so-quickview">
          <div className="iframe-link btn-button quickview quickview_handler visible-lg">
            <i className="fa fa-search" />
            <span>Quick view</span>
          </div>
        </div>
        {/*end quickview*/}
      </div>
      <div className="right-block">
        <div className="button-group cartinfo--static">
          <button type="button" className="addToCart" title="Advert by">
            <span>By {item.dealer_name}</span>
          </button>
          <button
            type="button"
            className="compare btn-button"
            title="Call user"
            onClick={() => {
              window.open("tel:" + item.dealer.phone, "_self");
            }}
          >
            <i className="fa fa-phone" />
          </button>
        </div>
        <h4>{item.title}</h4>
      </div>
      <div className="price-wrap">
        <span className="price">₦{formatNumber(item.price)}</span>
        <span className="condit">{item.condition.name}</span>
      </div>
    </Link>
  );
}

export default ProductItem;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Verify(props) {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [verifyError, setVerifyError] = useState("");
  useEffect(() => {
    const submit = () => {
      setLoading(true);
      axios
        .get("https://dashboard.maineautoparts.com/api/verify/" + params.token)
        .then(() => {
          setLoading(false);
          setSuccess(true);
          toast.success("Verified!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((error) => {
          toast.error("Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setVerifyError(error.response.data.message);
          setLoading(false);
        });
    };
    submit();
  }, [params]);
  return (
    <div className="container mb-100">
      <ToastContainer />
      <div className="row">
        <div className="col-lg-12">
          <div className="card-sign-up">
            <h3 className="form-title">
              {success
                ? "Account Verified"
                : verifyError
                ? "Failed"
                : "Verifying account"}
            </h3>
            {verifyError && (
              <div className="text-center">
                <svg
                  viewBox="0 0 24 24"
                  width="60px"
                  height="60px"
                  fill="var(--toastify-icon-color-error)"
                >
                  <path d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path>
                </svg>
                <br />
                <span className="error">{verifyError}</span>
              </div>
            )}
            {success ? (
              <div className="text-center">
                <p>Your account has been verified, login to continue.</p>
                <img src="/image/success2.gif" style={{ maxWidth: 160 }} />
                <p className="timer">
                  <br></br>

                  <Link to="/login">
                    <strong>Login Now </strong>
                  </Link>
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm onPageloader"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verify;

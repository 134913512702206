import React from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { SiAndroidauto, SiClyp, SiDatabricks, SiLbry } from "react-icons/si";
import { HiUser } from "react-icons/hi";
import { ImExit } from "react-icons/im";
import axios from "axios";
import Listing from "./Listing";
import Enquiries from "./Enquiries";
import CreateListing from "./CreateListing";
import ListItem from "./ListItem";
import Overlay from "../../components/Overlay";
import EditListing from "./EditListing";
import FavListing from "./FavListing";
import { AppContext } from "../../context/AppContext";
import UpdateProfile from "./UpdateProfile";
function Dashboard(props) {
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();
  const [active, setActive] = React.useState(1);
  const [display, setDisplay] = React.useState(1);
  const [enquiries, setEnquiries] = React.useState([]);
  const [inactiveAds, setInactiveAds] = React.useState([]);
  const [activeAds, setActiveAds] = React.useState([]);
  const [favourites, setFavourites] = React.useState([]);
  const [allAds, setAllAds] = React.useState([]);
  const [getAds, setGetAds] = React.useState(false);
  const [user, setUser] = React.useState("");
  var element = document.getElementById("sidebar");
  var toggle = document.getElementById("toggle");
  const setActiveTab = (act) => {
    setActive(act);
  };
  const signOut = () => {
    localStorage.removeItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    document.getElementById("toggle").classList.remove("switch");
    navigate("../login", { replace: true });
  };
  React.useEffect(() => {
    if (display == 0) {
      setDisplay(1);
    }
    let user = localStorage.getItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    if (user == null) {
      navigate("../login", { replace: true });
      return false;
    }
    user = JSON.parse(user);
    setUser(user);
    // INACTIVE ADVERTS
    axios({
      method: "get",
      url: "https://dashboard.maineautoparts.com/api/dealer-adverts?status=inactive",
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    }).then((response) => {
      let ads = response.data.data;
      setInactiveAds(ads);
    });
    // ACTIVE ADVERTS
    axios({
      method: "get",
      url: "https://dashboard.maineautoparts.com/api/dealer-adverts?status=active",
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    }).then((response) => {
      let ads = response.data.data;
      setActiveAds(ads);
    });
    // ALL ADVERTS
    axios({
      method: "get",
      url: "https://dashboard.maineautoparts.com/api/dealer-adverts",
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    }).then((response) => {
      let ads = response.data.data;
      setAllAds(ads);
      setDisplay(0);
    });
    // FAVOURITES
    axios({
      method: "get",
      url: "https://dashboard.maineautoparts.com/api/allFavourites",
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    }).then((response) => {
      let ads = response.data;
      setFavourites(ads);
    });
  }, [getAds]);

  React.useEffect(() => {
    //ENQUIRIES
    axios
      .get("https://dashboard.maineautoparts.com/api/enquiries")
      .then((response) => {
        let ads = response.data.data;
        setEnquiries(ads);
      });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (location.pathname == "/all-listing") {
      setActiveTab(1);
    } else if (location.pathname == "/active-listing") {
      setActiveTab(2);
    } else if (location.pathname == "/inactive-listing") {
      setActiveTab(3);
    } else if (location.pathname == "/part-requests") {
      setActiveTab(4);
    } else if (location.pathname == "/create-advert") {
      setActiveTab(5);
    } else if (location.pathname == "/wishlist") {
      setActiveTab(8);
    } else if (location.pathname == "/update-profile") {
      setActiveTab(9);
    } else if (params.item_id != undefined) {
      setActiveTab(6);
    } else if (params.edit_id != undefined) {
      setActiveTab(7);
    }
  }, [location]);
  ///unfavourite
  const remove = (id) => {
    let favs = { ...favourites };
    favs.data = favourites.data.filter(function (el) {
      return el.advert_id != id;
    });
    setFavourites(favs);
    axios.put(
      "https://dashboard.maineautoparts.com/api/favourite/" + id,
      {},
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    );
  };
  return (
    <div className="dashboard">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <ul className="sidebar" id="sidebar">
              <li className={active == 1 ? "active" : ""}>
                <Link to="/all-listing" onClick={() => setActiveTab(1)}>
                  {" "}
                  All Listing
                </Link>
              </li>
              <li className={active == 2 ? "active" : ""}>
                <Link onClick={() => setActiveTab(2)} to="/active-listing">
                  {" "}
                  Active Listing
                </Link>
              </li>
              <li className={active == 3 ? "active" : ""}>
                <Link onClick={() => setActiveTab(3)} to="/inactive-listing">
                  {" "}
                  Inactive Listing
                </Link>
              </li>
              <li className={active == 8 ? "active" : ""}>
                <Link onClick={() => setActiveTab(8)} to="/wishlist">
                  Wishlist
                </Link>
              </li>
              <li className={active == 4 ? "active" : ""}>
                <Link onClick={() => setActiveTab(4)} to="/part-requests">
                  Parts Request
                </Link>
              </li>
              <div className="ttp">
                <li className={active == 5 ? "active" : ""}>
                  <Link onClick={() => setActiveTab(5)} to="/create-advert">
                    <SiClyp /> Create Advert
                  </Link>
                </li>
                <li className={active == 6 ? "active" : ""}>
                  <Link to="#">
                    <SiAndroidauto />
                    Premium Services
                  </Link>
                </li>
                <li className={active == 9 ? "active" : ""}>
                  <Link onClick={() => setActiveTab(9)} to="/update-profile">
                    <HiUser />
                    Update Profile
                  </Link>
                </li>
              </div>
              <li className="ttp">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    signOut();
                  }}
                  to="#"
                >
                  <ImExit />
                  Sign Out
                </Link>
              </li>
              <li className="dflex sm-no">
                <img src="/image/appstore.svg" className="img2" />
                <img src="/image/playstore.png" className="img2" />
              </li>
              <li className="sm-no">
                <p>© 2022 Maine auto parts</p>
              </li>
            </ul>
          </div>
          <div className="col-md-9">
            <div className="dash-main">
              {active == 1 ? (
                <Listing
                  reload={() => setGetAds(!getAds)}
                  adverts={allAds}
                  title="All Listing"
                />
              ) : (
                <></>
              )}
              {active == 2 ? (
                <Listing
                  reload={() => setGetAds(!getAds)}
                  adverts={activeAds}
                  title="Active Listing"
                />
              ) : (
                <></>
              )}

              {active == 3 ? (
                <Listing
                  reload={() => setGetAds(!getAds)}
                  adverts={inactiveAds}
                  title="Inactive Listing"
                />
              ) : (
                <></>
              )}
              {active == 6 ? (
                <ListItem reload={() => setGetAds(!getAds)} />
              ) : (
                <></>
              )}
              {active == 4 ? (
                <Enquiries
                  adverts={enquiries}
                  title="All Requests Made By MaineAutoParians"
                  subtitle="(Please upload parts
              requested below)"
                />
              ) : (
                <></>
              )}
              {active == 5 ? (
                <CreateListing reload={() => setGetAds(!getAds)} />
              ) : (
                <></>
              )}
              {active == 7 ? (
                <EditListing reload={() => setGetAds(!getAds)} />
              ) : (
                <></>
              )}
              {active == 8 ? (
                <FavListing
                  reload={() => setGetAds(!getAds)}
                  adverts={favourites}
                  user={user}
                  loading={() => setDisplay(true)}
                  unfavourite={(id) => remove(id)}
                  title="Wishlist"
                />
              ) : (
                <></>
              )}
              {active == 9 ? (
                <UpdateProfile user={user} title="Update Profile" />
              ) : (
                <></>
              )}
            </div>
            <ul className="sm-show">
              <li>
                <img
                  src="/image/appstore.svg"
                  className="img2"
                  style={{
                    width: 110,
                    marginTop: 20,
                    marginBottom: 20,
                    marginRight: 10,
                  }}
                />
                <img
                  src="/image/playstore.png"
                  className="img2"
                  style={{
                    width: 110,
                    marginTop: 20,
                    marginBottom: 20,
                    marginRight: 10,
                  }}
                />
              </li>
              <li>
                <p>© 2022 Maine auto parts</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Overlay display={display} />
    </div>
  );
}

export default Dashboard;

import React, { useContext, useState } from "react";

import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { IoTrash } from "react-icons/io5";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Import the plugin code
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import { AppContext } from "../../context/AppContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Overlay from "../../components/Overlay";
import states from "../../data/states";
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

function UpdateProfile({ user }) {
  const [files, setFiles] = React.useState([]);
  const [active, setActive] = React.useState(true);
  const { reload, setReload } = useContext(AppContext);
  const params = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  /*** FORM INPUTS */
  const [Firstname, setFirstname] = React.useState(user?.user?.first_name);
  const [Lastname, setLastname] = React.useState(user?.user?.last_name);
  const [company_name, setcompany_name] = React.useState(
    user?.user?.userable?.company_name
  );
  const [gender, setgender] = React.useState(user?.user?.userable?.gender);
  const [address, setaddress] = React.useState(
    user?.user?.user_address?.address
  );
  const [city, setcity] = React.useState(user?.user?.user_address?.city);
  const [lga, setlga] = React.useState(user?.user?.user_address?.lga);
  const [state, setstate] = React.useState(user?.user?.user_address?.state);
  const [alternative_number, setalternative_number] = React.useState(
    user?.user?.userable?.alternative_number
  );
  const [phone, setphone] = React.useState(user?.user?.userable?.phone);
  const [Email, setEmail] = React.useState(user?.user?.userable?.email);
  const [oldpassword, setoldpassword] = React.useState("");
  const [newpassword, setnewpassword] = React.useState("");
  const [confirmpassword, setconfirmpassword] = React.useState("");
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [display, setDisplay] = React.useState(1);
  const [advert, setAdvert] = useState("");
  const submit = (e) => {
    e.preventDefault();
    //return false;
    setLoading(true);
    setErrors([]);
    let user = localStorage.getItem("xompsmpsnd101010299msiknsloa11292nsinsi");
    user = JSON.parse(user);
    const formData = new FormData();
    console.log(files);
    for (var i in files) {
      formData.append("avatar", files[i].file);
    }
    formData.append("email", user?.user?.email);
    formData.append("company_name", company_name);
    formData.append("gender", gender);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("alternative_number", alternative_number);
    formData.append("first_name", Firstname);
    formData.append("last_name", Lastname);
    formData.append("is_company", 1);
    axios
      .post(
        "https://dashboard.maineautoparts.com/api/update-dealer",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        const new_user = response.data.data;
        console.log(new_user);
        user.user = new_user;
        user = JSON.stringify(user);
        localStorage.setItem("xompsmpsnd101010299msiknsloa11292nsinsi", user);
        setErrors([]);
        toast.success("Profile updated!", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setReload(!reload);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 401) {
          setErrors([]);
        } else {
          setErrors(error?.response?.data.errors);
        }
        toast.error("Error occured!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };
  const change = () => {
    setLoading(true);
    setErrors([]);
    const formData = new FormData();
    formData.append("password", oldpassword);
    formData.append("new_password", newpassword);
    formData.append("confirm_password", confirmpassword);
    axios
      .post(
        "https://dashboard.maineautoparts.com/api/change-password/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setErrors([]);
        setoldpassword("");
        setnewpassword("");
        setconfirmpassword("");
        toast.success("Password updated!", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 401) {
          setErrors([]);
        }
        if (error?.response?.status === 403) {
          let err = [...errors];
          err.password = ["Incorrect password"];
          setErrors(err);
        } else {
          setErrors(error?.response?.data.errors);
        }
        toast.error("Error occured!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };
  React.useEffect(() => {
    setErrors([]);
  }, [params]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        darkmode
      />
      <div className="title">
        <h1>{active ? "Update Profile" : "Change Password"}</h1>
        <li onClick={() => setActive(!active)} style={{ cursor: "pointer" }}>
          {!active ? "Update Profile" : "Change Password"}
        </li>
      </div>
      <div className="ImageUpload" style={{ paddingTop: 0, paddingBottom: 15 }}>
        {active ? (
          <>
            <div className="row">
              <div
                className={`
              col-md-12
              ${errors?.images ? " is-invalid" : ""}
              ${files.length == 0 ? "" : "top"}`}
                id="avat"
              >
                <FilePond
                  files={files}
                  onupdatefiles={(fileItems) => {
                    setFiles(fileItems);
                  }}
                  allowMultiple={false}
                  allowReorder={true}
                  maxFiles={1}
                  maxFileSize="1MB"
                  instantUpload={false}
                  allowProcess={false}
                  storeAsFile={true}
                  allowFileTypeValidation={true}
                  allowFileSizeValidation={true}
                  acceptedFileTypes={["image/*"]}
                  //server="/api"
                  name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </div>
              <div className="col-md-6">
                <label>First Name</label>
                <input
                  name="first_name"
                  type="text"
                  className={[
                    "overide input-text ",
                    errors?.first_name ? " is-invalid" : "",
                  ]}
                  defaultValue={user?.user?.first_name}
                  onChange={(event) => setFirstname(event.target.value)}
                />
                {errors?.first_name != undefined ? (
                  <p className="error">{errors?.first_name[0]}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6">
                <label>Last Name</label>
                <input
                  name="last_name"
                  type="text"
                  defaultValue={user?.user?.last_name}
                  onChange={(event) => setLastname(event.target.value)}
                  className={[
                    "overide input-text ",
                    errors?.last_name ? " is-invalid" : "",
                  ]}
                />
                {errors?.last_name != undefined ? (
                  <p className="error">{errors?.last_name[0]}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6">
                <label>Alternative number</label>
                <input
                  name="alternative_number"
                  type="text"
                  defaultValue={alternative_number}
                  onChange={(event) =>
                    setalternative_number(event.target.value)
                  }
                  className={[
                    "overide input-number ",
                    errors?.alternative_number ? " is-invalid" : "",
                  ]}
                />
                {errors?.alternative_number != undefined ? (
                  <p className="error">{errors?.alternative_number[0]}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6">
                <label>Phone number</label>
                <input
                  name="phone"
                  type="text"
                  defaultValue={phone}
                  onChange={(event) => setphone(event.target.value)}
                  className={[
                    "overide input-number ",
                    errors?.phone ? " is-invalid" : "",
                  ]}
                />
                {errors?.phone != undefined ? (
                  <p className="error">{errors?.phone[0]}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6">
                <label>Company Name</label>
                <input
                  name="company_name"
                  type="text"
                  defaultValue={company_name}
                  onChange={(event) => setcompany_name(event.target.value)}
                  className={[
                    "overide input-number ",
                    errors?.company_name ? " is-invalid" : "",
                  ]}
                />
                {errors?.company_name != undefined ? (
                  <p className="error">{errors?.company_name[0]}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6">
                <label>Gender</label>
                <select
                  name="gender"
                  defaultValue={gender}
                  onChange={(event) => setgender(event.target.value)}
                  className={[
                    "overide input-number ",
                    errors?.gender ? " is-invalid" : "",
                  ]}
                >
                  <option value="">-- Select Gender --</option>
                  <option value="male"> Male </option>
                  <option value="female"> Female </option>
                </select>
                {errors?.gender != undefined ? (
                  <p className="error">{errors?.gender[0]}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6">
                <label>State</label>
                <select
                  name="state"
                  defaultValue={state}
                  onChange={(event) => setstate(event.target.value)}
                  className={[
                    "overide input-number ",
                    errors?.state ? " is-invalid" : "",
                  ]}
                >
                  <option value="">-- Select State --</option>
                  {states.map((item, key) => {
                    return (
                      <option value={item} key={key}>
                        {" "}
                        {item}{" "}
                      </option>
                    );
                  })}
                </select>
                {errors?.state != undefined ? (
                  <p className="error">{errors?.state[0]}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6">
                <label>Address</label>
                <input
                  name="address"
                  type="text"
                  placeholder="Address"
                  defaultValue={address}
                  onChange={(event) => setaddress(event.target.value)}
                  className={[
                    "overide input-number ",
                    errors?.address ? " is-invalid" : "",
                  ]}
                />
                {errors?.address != undefined ? (
                  <p className="error">{errors?.address[0]}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <button
                  type="submit"
                  className="sign-up-btn"
                  onClick={active ? submit : change}
                >
                  {active ? "Update" : "Change Password"}
                  <span
                    className="spinner-border spinner-border-sm onPageloader"
                    role="status"
                    aria-hidden="true"
                    style={{ display: loading ? "inline-block" : "none" }}
                  ></span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-md-6">
              <br></br>
              <div className="col-md-12">
                <label>Current Password</label>
                <input
                  name="old_password"
                  type="password"
                  value={oldpassword}
                  className={[
                    "overide input-text ",
                    errors?.password ? " is-invalid" : "",
                  ]}
                  autoComplete="off"
                  onChange={(event) => setoldpassword(event.target.value)}
                />
                {errors?.password != undefined ? (
                  <p className="error">{errors?.password[0]}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12">
                <label>New Password</label>
                <input
                  name="new_password"
                  type="password"
                  value={newpassword}
                  className={[
                    "overide input-text ",
                    errors?.new_password ? " is-invalid" : "",
                  ]}
                  autoComplete="off"
                  onChange={(event) => setnewpassword(event.target.value)}
                />
                {errors?.new_password != undefined ? (
                  <p className="error">{errors?.new_password[0]}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-12">
                <label>Confirm New Password</label>
                <input
                  name="confirm_password"
                  type="password"
                  value={confirmpassword}
                  className={[
                    "overide input-text ",
                    errors?.confirm_password ? " is-invalid" : "",
                  ]}
                  onChange={(event) => setconfirmpassword(event.target.value)}
                />
                {errors?.confirm_password != undefined ? (
                  <p className="error">{errors?.confirm_password[0]}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  className="sign-up-btn"
                  onClick={active ? submit : change}
                >
                  {active ? "Update" : "Change Password"}
                  <span
                    className="spinner-border spinner-border-sm onPageloader"
                    role="status"
                    aria-hidden="true"
                    style={{ display: loading ? "inline-block" : "none" }}
                  ></span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UpdateProfile;

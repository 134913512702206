import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { IoIosArrowDown } from "react-icons/io";

function Faq(props) {
  return (
    <div>
      <div className="help-bac">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="header-help">Help center</h3>
              <p className="header-body">A handy guide To Maine Auto Parts</p>
              <Link to="/contact">
                <button className="help-button">Contact Us</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container page faq-container">
        <h3 class="for-buyers">General Information</h3>
        <div className="faq-area">
          <div className="faq-content">
            <div className="panel-group" id="accordion">
              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse1"
                    >
                      <IoIosArrowDown />
                      What is the "MaineAutoPart"?
                    </a>
                  </h4>
                </div>
                <div id="collapse1" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      An online Based Marketplace for Sales of Spare part from
                      reliable dealers In Nigeria
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse2"
                    >
                      <IoIosArrowDown />
                      Do you have any stores?
                    </a>
                  </h4>
                </div>
                <div id="collapse2" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      No, as we are not a shop. — Here you can all Automobile
                      spare parts of all sorts, any deal you make, and you make
                      with people. And we exist just ensure you get the best
                      deals with reputable Spare Parts Dealers
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse3"
                    >
                      <IoIosArrowDown />
                      Do you have a delivery?
                    </a>
                  </h4>
                </div>
                <div id="collapse3" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      No, we don’t. But you always can discuss with the seller a
                      comfortable way of delivering.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -- FAQ AREA -- */}

        <h3 class="for-buyers">For Buyers</h3>
        <div class="ft-h">
          <strong>How can I buy something on MaineAutoParts?</strong> To enjoy
          buying on MaineAutoParts, you need to follow our simple guide:
        </div>
        <div className="faq-area">
          <div className="faq-content">
            <div className="panel-group" id="accordion">
              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse4"
                    >
                      <IoIosArrowDown />
                      Search for the item
                    </a>
                  </h4>
                </div>
                <div id="collapse4" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      Use the search bar with filters to find what you need. We
                      have over a million Ads, so you can choose exactly what
                      you are looking for.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse5"
                    >
                      <IoIosArrowDown />
                      Contact a seller
                    </a>
                  </h4>
                </div>
                <div id="collapse5" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      You may contact a dealer on MaineAutoParts via mobile
                      calls, setting up a meeting face to face, to discuss some
                      details or negotiate about the price.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse6"
                    >
                      <IoIosArrowDown />
                      Picking up an item
                    </a>
                  </h4>
                </div>
                <div id="collapse6" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      We approve our dealers carefully, but it's always better
                      to check what your picking or buying carefully Meet with
                      the seller in a public place and be sure to pay only after
                      you get the item.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -- FAQ AREA -- */}

        <div class="ft-h mt-40">
          <strong>How can I protect myself from being scammed?</strong> We are
          highly focused on security and try to solve any issues in short terms.
          Please, check the general tips on how to buy safely:
        </div>
        <div className="faq-area">
          <div className="faq-content">
            <div className="panel-group" id="accordion">
              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse7"
                    >
                      <IoIosArrowDown />
                      Meet with sellers face to face in a public place
                    </a>
                  </h4>
                </div>
                <div id="collapse7" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      Check out the item's quality and make sure that it lives
                      up your expectations before you make payment.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse8"
                    >
                      <IoIosArrowDown />
                      Never give out your financial information that could be
                      misused
                    </a>
                  </h4>
                </div>
                <div id="collapse8" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      Be attentive and avoid scams and frauds, such as fake
                      payment services, fee requests or requests to use money
                      transfer services.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse9"
                    >
                      <IoIosArrowDown />
                      Be careful and check the company's background twice
                    </a>
                  </h4>
                </div>
                <div id="collapse9" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      Don't submit any upfront fees for a job application; Don't
                      go to the remote or unfamiliar places for an interview;
                      Don't disclose any personal information like a bank
                      account number or photocopy of your ID.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse10"
                    >
                      <IoIosArrowDown />
                      How can I be sure that I'll get what I requested?
                    </a>
                  </h4>
                </div>
                <div id="collapse10" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      We try to check our sellers carefully, but it’s almost
                      impossible to vouch for each of them. Expectations matter,
                      and to avoid disappointments, we strongly recommend you
                      meet with sellers face to face in a public place. Review
                      the product first, and pay for it only if you are
                      satisfied.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -- FAQ AREA -- */}
        <h3 class="for-buyers">Posting Ads</h3>
        <div className="faq-area">
          <div className="faq-content">
            <div className="panel-group" id="accordion">
              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse11"
                    >
                      <IoIosArrowDown />
                      How to post an advert on MaineAutoParts
                    </a>
                  </h4>
                </div>
                <div id="collapse11" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      a. Click on Post Ad <br></br>
                      b. Complete all the information about your item such as
                      title, region, description, price and add photos <br></br>
                      c. After filling out the required fields, click on “Post”
                      button. <br></br>d. Once you post your ad, you will be
                      immediately redirected to our registration page (if you
                      are not a registered Dealer). You will have to fill out
                      all the required fields and click on ‘Register’ button at
                      the bottom of the page. <br></br>e. Your advert will be
                      published shortly once moderation process is completed.{" "}
                      <br></br>f. Once your advert is live, you will receive a
                      notification email. <br></br>g. Be ready to receive
                      numerous incoming calls from your potential buyers. Good
                      luck with your sales!
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse12"
                    >
                      <IoIosArrowDown />
                      What happens after I post my ad?
                    </a>
                  </h4>
                </div>
                <div id="collapse12" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      a. Our moderators will review your ad very carefully and
                      if all the fields are filled out correctly, it will be
                      activated shortly. <br></br>b. You can find your advert on
                      our website only when it is approved by our moderators.{" "}
                      <br></br>c. You will get a notification email when your
                      advert is active. If there is something wrong with your
                      advert, you will be notified about that and all the
                      mistakes will be specified so that you can edit your
                      advert correspondingly. <br></br>d. After publishing your
                      advert, you can edit it again if needed and repost. Once
                      done, your advert undergoes moderation before being
                      activated on the website. <br></br>e. You can delete or
                      close your advert any time you want. You just have to
                      click on Close button placed under the ad or click on the
                      cross placed on the top right corner of the ad. <br></br>
                      f. You shouldn’t publish adverts of the same content. They
                      will be considered duplicates by our moderators and won’t
                      go active on the website. <br></br>g. You can always
                      manage your ads by logging in to your MaineAutoParts
                      account and clicking on My Adverts.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse13"
                    >
                      <IoIosArrowDown />
                      Posting rules
                    </a>
                  </h4>
                </div>
                <div id="collapse13" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      Following rules are required while you are posting adverts
                      on MaineAutoParts <br></br>a. Every advert must have a
                      precise title. <br></br>b. Every advert should contain
                      only unique images (taken by the seller and not downloaded
                      from the Internet) without any contact information or
                      watermarks. <br></br>c. Every advert must contain images.
                      <br></br>d. The prices of your items must correspond to
                      the real prices of similar products. <br></br>e. All
                      posted products and/or services must be located in
                      Nigeria. <br></br>f. All items and products must be
                      legally permitted. <br></br>g. Each item for sale must be
                      posted separately. You cannot post several products within
                      one and the same advert. <br></br>h. Every advert must
                      contain a brief and clear description.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse14"
                    >
                      <IoIosArrowDown />
                      Tips for creating an effective ad
                    </a>
                  </h4>
                </div>
                <div id="collapse14" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      If you really want to create a great ad, it is highly
                      recommended to follow the instructions below: <br></br>a.
                      Use a clear title which includes the name of the item you
                      sell. Try to make your title appealing and eye-catching.{" "}
                      <br></br>b. Set an appropriate price for your item so that
                      the advert is approved. If the price is not relevant, it
                      may be declined. Make sure to carry out some investigation
                      of the prices for a particular item. <br></br>c. The
                      description of your product must be informative enough and
                      mustn’t contain any false information regarding your
                      product or service. <br></br>d. Upload only unique and
                      high-quality photos of your items taken by yourself and
                      not downloaded from the Internet. The better photos you
                      add, the more attractive your ad looks to the potential
                      buyers and the more calls you receive. <br></br>e.
                      Indicate correct contact details for the potential
                      buyers/clients to be able to reach you easily. Try to
                      respond all the incoming calls or to call back your
                      customers once available. <br></br>f. Try to fill out all
                      the fields of your profile page, as well as those of your
                      advert, to let your customers dispose of all the necessary
                      information about you as a seller and the products you
                      sell. <br></br>g. The better rating you have on our
                      website, the more chances you get to attract a lot of
                      buyers. Remember that it is important to build trust in
                      your business. Your rating depends on the number of
                      positive/negative feedback received from your previous
                      customers. <br></br>h. Make your advert as risk-free as
                      possible. Underline that no prepayments are required and
                      be ready to list those delivery services which presuppose
                      payment on the delivery of the product ordered.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -- FAQ AREA -- */}
        <h3 class="for-buyers">For Dealers</h3>
        <div className="faq-area">
          <div className="faq-content">
            <div className="panel-group" id="accordion">
              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse15"
                    >
                      <IoIosArrowDown />
                      How can I sell on MaineAutoParts?
                    </a>
                  </h4>
                </div>
                <div id="collapse15" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      Post your ads on MaineAutoParts fast, just take the
                      following steps to proceed: Notes: if you are not a
                      registered user, the first step will be registration.
                      <br></br> a. Click on the button "Register"; <br></br>b.
                      Fill All the all the information Required;<br></br> c.
                      Upload your photos and write a clear title with a full
                      description of your item. After that, enter a fair price,
                      select attributes and send your advert to review! After
                      filling out the required fields, click on "Post Ad"
                      button; When you've done all of these steps, our
                      moderators will check your advert. Once your advert is
                      live, you will receive a notification email. If there's
                      something wrong, we'll specify all the mistakes you should
                      edit. That's all you need to do! Welcome to our great
                      MaineAutoParts Fam!
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse17"
                    >
                      <IoIosArrowDown />
                      I've posted my ad but I can't find it on MaineAutoParts.
                      Why?
                    </a>
                  </h4>
                </div>
                <div id="collapse17" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      At first, don't worry! If you've faced such a situation,
                      we recommend:<br></br> a. Wait for a while before your ad
                      passes the moderation. It's quite possible that our
                      moderators are checking your advert and it will appear in
                      search results within a few hours. <br></br>b. Check your
                      inbox. There you will get a notification when your advert
                      goes live. Also, if there is something wrong with your
                      advert, we'll specify all the mistakes you should edit.
                      Important! If you make some changes in your advert, it
                      must pass the moderation one more time. Temporarily we
                      won't show it in search results. You can always manage
                      your ads, just sign in to your MaineAutoParts account and
                      click on My Adverts.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse18"
                    >
                      <IoIosArrowDown />
                      Are there any rules concerning posting adverts on
                      MaineAutoParts?
                    </a>
                  </h4>
                </div>
                <div id="collapse18" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      a. You must add a precise title for each advert;<br></br>{" "}
                      b. Make sure you add brief and clear description without
                      any false information; <br></br>c. Every advert should
                      contain only unique images (taken by you) without any
                      contact information or watermarks. <br></br>d. Make sure
                      you choose an appropriate category. Be attentive choosing
                      a category for a job proposal or a resume. If you mention
                      the wrong category, we can't approve your ad.<br></br> e.
                      The prices of your items must correspond to the real
                      prices of similar products. <br></br>f. Indicate correct
                      contact details, so that your potential customers could
                      reach you easily. Try to respond to all the incoming calls
                      or to call back your customers once it's possible.{" "}
                      <br></br>g. All posted products and services must be
                      located in Nigeria. <br></br>h. All items and products
                      must be legally permitted (you can check the list of
                      prohibited items here) <br></br>i. Publish all items for
                      sale separately. There is a rule: “1 ad = 1 item”.
                      <br></br> j. Create different adverts. If there are
                      identical ads we consider them as duplicates and don’t
                      approve.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse19"
                    >
                      <IoIosArrowDown />
                      My advert has been declined. Why?
                    </a>
                  </h4>
                </div>
                <div id="collapse19" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      No worries, let's figure it out together! Our moderators
                      could decline your ad for several reasons: <br></br>a. You
                      tried to post a few items within one ad. It’s not allowed
                      on MaineAutoParts. You must post each item separately: 1
                      ad = 1 product. <br></br>b. You’ve posted pictures of
                      nudity or images that contain contact details, watermarks,
                      etc. We don't allow posting these kinds of photos. Please,
                      remove them and add real photos of the item that you
                      intend to sell. <br></br>c. You have set the irrelevant
                      price for your item. Make sure to carry out some
                      investigation of market value and input an appropriate
                      price for your advert. Follow these simple rules and our
                      moderators will activate your ad in a short time.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse20"
                    >
                      <IoIosArrowDown />
                      Can I share ads on social networks?
                    </a>
                  </h4>
                </div>
                <div id="collapse20" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      Sure! It helps a lot to find more clients. If you want to
                      share the ad on social media, follow these steps:{" "}
                      <br></br>a. Sign in to your MaineAutoParts account;
                      <br></br> b. Go to My adverts; <br></br>c. Click on the
                      advert you want to share; <br></br>d. Scroll down until
                      you see Facebook/Twitter icons; <br></br>e. Click on a
                      particular icon to be able to share the link. That's it!
                      Now all your friends in social networks know about the
                      item you sell!
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse21"
                    >
                      <IoIosArrowDown />
                      How long will my ads stay on MaineAutoParts?
                    </a>
                  </h4>
                </div>
                <div id="collapse21" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      Your ads remain on the site for 1-3 months (since the date
                      of the last renewal) before they are automatically deleted
                      or until you decide to deactivate them. Please note that
                      you can update your ad if you haven't sold your item.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default general-questions">
                <div className="panel-heading">
                  <h4 className="panel-title on">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse23"
                    >
                      <IoIosArrowDown />
                      What can I do to sell better on MaineAutoParts?
                    </a>
                  </h4>
                </div>
                <div id="collapse23" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>
                      We prepared for you a few pieces of advice on how to sell
                      like a pro: <br></br>a. Pay attention to the details Take
                      the relevant photos of your goods, write a clear detailed
                      description and fill out all the fields in your profile.
                      All of this will help your customers to learn more about
                      you and your product. <br></br>b. Make your advert as
                      risk-free, as possible Remember, that you can't ask for
                      prepayments and be ready to list those delivery services
                      that presuppose payment after the product arrived.{" "}
                      <br></br>c. Try to reply quickly to requests Don't make
                      your buyers wait for your replies too long. Be online or
                      subscribe to Boost packages to always get
                      SMS-notifications on new messages.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -- FAQ AREA -- */}
      </div>
      <Footer />
    </div>
  );
}

export default Faq;

import React, { useContext } from "react";
import ProductScreenDetails from "../components/Product/ProductScreenDetails";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Footer from "./Layout/Footer";
import { AppContext } from "../context/AppContext";

function Product() {
  var { categories, brands, favourites } = useContext(AppContext);

  let params = useParams();
  const [advert, setAdvert] = React.useState([]);
  const [latests, setLatests] = React.useState([]);
  React.useEffect(() => {
    let link = "https://dashboard.maineautoparts.com/api/adverts/" + params.id;
    axios.get(link).then((response) => {
      ////check if item is already in array
      let brand = brands.filter(function (el) {
        return el.id == response.data.brand_id;
      });
      var isFavourite;
      if (favourites.length == 0) {
        let user = localStorage.getItem(
          "xompsmpsnd101010299msiknsloa11292nsinsi"
        );
        user = JSON.parse(user);
        if (user != null) {
          axios({
            method: "get",
            url: "https://dashboard.maineautoparts.com/api/allFavourites",
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          }).then((response) => {
            let favourites = response.data.data;
            isFavourite = favourites.filter(function (el) {
              return el.advert_id == response.data.id;
            });
          });
        }
      } else {
        isFavourite = favourites.filter(function (el) {
          return el.advert_id == response.data.id;
        });
      }
      if (isFavourite != false) {
        response.data.isFavourite = true;
      }
      response.data.brand = brand[0];
      setAdvert(response.data);
    });
    axios
      .get(
        "https://dashboard.maineautoparts.com/api/search-adverts?featured=0&category_id=&brand_id=&dealer_id&minimumPrice=&maximumPrice&search="
      )
      .then((response) => {
        let ads = response.data.data;
        ads = ads.slice(0, 8);
        setLatests(ads);
      });
  }, [params]);

  return (
    <div>
      <div className="main-container container">
        <ul className="breadcrumb">
          <li>
            <Link to="/">
              <i className="fa fa-home"></i>
            </Link>
          </li>
          <li>
            <Link
              to={
                "/category/" +
                advert?.category?.name +
                "/" +
                advert?.category?.id
              }
            >
              {advert?.category?.name}
            </Link>
          </li>
          <li>
            <Link to="#">{advert?.title}</Link>
          </li>
        </ul>
        <div className="row">
          <ProductScreenDetails
            advert={advert}
            latests={latests}
            title={params.title}
            id={params.id}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Product;

import axios from "axios";
import React from "react";
import { Link, useParams } from "react-router-dom";

function ResetPassword(props) {
  const params = useParams();
  let token = params.token;
  const [Firstname, setFirstname] = React.useState("");
  const [Lastname, setLastname] = React.useState("");
  const [Phone, setPhone] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [Password, setPassword] = React.useState("");
  const [PasswordConfirmation, setPasswordConfirmation] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const submit = (e) => {
    e.preventDefault();

    setLoading(true);
    const formData = new FormData();
    formData.append("password", Password);
    formData.append("password_confirmation", PasswordConfirmation);
    formData.append("email", Email);
    formData.append("token", token);
    axios
      .post(
        "https://dashboard.maineautoparts.com/api/password/reset",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        setLoading(false);
        setSuccess(
          "Your password reset is successful, kindly login with new password."
        );
      })
      .catch((error) => {
        setLoading(false);
        setErrors(error.response.data.errors);
      });
  };
  React.useEffect(() => {
    setErrors([]);
  }, [params]);
  return (
    <div className="container mb-100">
      <div className="row">
        <div className="col-lg-12">
          <div className="card-sign-up">
            <h3 className="form-title">Reset password</h3>
            {success ? (
              <p className="success">
                {success} <Link to="/login">Login</Link>
              </p>
            ) : (
              ""
            )}
            <form action="#" method="post">
              <input
                name="email"
                type="email"
                className={[
                  "overide input-email ",
                  errors.email ? " is-invalid" : "",
                ]}
                placeholder="Email address e.g john@gmail.com"
                defaultValue={Email}
                onChange={(event) => setEmail(event.target.value)}
              />
              {errors.email != undefined ? (
                <p className="error">{errors.email[0]}</p>
              ) : (
                ""
              )}
              <input
                name="password"
                type="password"
                placeholder="New password"
                defaultValue={Password}
                className={[
                  "overide input-password ",
                  errors.password ? " is-invalid" : "",
                ]}
                onChange={(event) => setPassword(event.target.value)}
              />
              {errors.password != undefined ? (
                <p className="error">{errors.password[0]}</p>
              ) : (
                ""
              )}
              <input
                name="password_confirmation"
                type="password"
                className="overide input-password"
                placeholder="Re-type password"
                defaultValue={PasswordConfirmation}
                onChange={(event) =>
                  setPasswordConfirmation(event.target.value)
                }
              />
              <button type="submit" className="sign-up-btn" onClick={submit}>
                Reset Password
                <span
                  className="spinner-border spinner-border-sm onPageloader"
                  role="status"
                  aria-hidden="true"
                  style={{ display: loading ? "inline-block" : "none" }}
                ></span>
              </button>
              <p style={{ fontSize: 12, lineHeight: "16px" }}>
                Your new password must be at least 6 characters long and contain
                at least 6 unique characters
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

import React, { useContext } from "react";
import Banners from "../components/Home/Banners";
import CatProductListing from "../components/Home/CatProductListing";
import ListingLayout1 from "../components/Home/ListingLayout1";
import Main from "../components/Home/Main";
import SoCategories from "../components/Home/SoCategories";
import { AppContext } from "../context/AppContext";
import Footer from "./Layout/Footer";

function Home() {
  const { topCategories, categories } = useContext(AppContext);
  return (
    <div>
      <div className="main-container">
        <div id="content">
          <div className="content-main-w">
            <Main />
            <SoCategories categories={categories} />
            <ListingLayout1 />

            <div className="container">
              <div className="row">
                {topCategories.map((value, index) => {
                  return (
                    <CatProductListing
                      title={value.name}
                      adverts={value.adverts}
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;

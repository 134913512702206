import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Qr(props) {
  let navigate = useNavigate();
  useEffect(() => {
    const getMobileOperatingSystem = () => {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        console.log("Windows Phone");
        navigate("/", { replace: true });
      }
      if (/android/i.test(userAgent)) {
        window.location.replace(
          "https://play.google.com/store/apps/details?id=com.maineautoparts"
        );
        console.log("Android");
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.replace(
          "https://apps.apple.com/in/app/maine-auto-parts/id6474206760"
        );
        console.log("iOS");
      }
      navigate("/", { replace: true });
    };
    getMobileOperatingSystem();
  }, []);
  return (
    <div className="mmmg">
      <span
        className="spinner-border spinner-border-sm onPageloader"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  );
}

export default Qr;

import React from "react";
import Footer from "./Layout/Footer";

function Terms(props) {
  return (
    <>
      <div className="container page">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="privacy-header">Privacy Policy &amp; Terms</h3>
            <p className="privacy-special">
              Effective as of December 1st, 2020.
            </p>
            <p className="privacy-body-text">
              Maineautoparts.com will never share your personal info with third
              parties.
            </p>
            <p className="privacy-body-text">
              Protecting your private information is our priority. This
              Statement of Privacy applies to the Maineautoparts.com and
              MaineAutoParts Company and governs data collection and usage. For
              the purposes of this Privacy Policy, unless otherwise noted, all
              references to Maineautoparts.com include MaineAutoParts Company
            </p>
            <p className="privacy-body-text">
              The MaineAutoParts website is for Sales of Automobile Spare Parts.
              By using the MaineAutoParts website, you consent to the data
              practices described in this statement.
            </p>
            <h6 className="privacy-header-6">
              Collection of your Personal Information
            </h6>
            <p className="privacy-body-text">
              Maineautoparts.com may collect personally identifiable
              information, such as your name, address, telephone number, email
              address. If you purchase MaineAutoParts’ products and services, we
              collect billing and credit card information. This information is
              used to complete the purchase transaction. Maineautoparts.com may
              also collect anonymous demographic information which is not unique
              to you, such as your age, gender, associated addresses, company
              name, preferred routes, and commuting frequency. We may gather
              additional personal or non-personal information in the future.
            </p>
            <p className="privacy-body-text">
              Information about your computer hardware and software may be
              automatically collected by Maineautoparts.com. This information
              can include: your IP address, browser type, domain names, access
              times and referring website addresses. This information is used
              for the operation of the service, to maintain quality of the
              service, and to provide general statistics regarding use of the
              MaineAutoParts website.
            </p>
            <p className="privacy-body-text">
              Please keep in mind that if you directly disclose personally
              identifiable information or personally sensitive data through
              Maineautoparts.com public message boards, this information may be
              collected and used by others.
            </p>
            <p className="privacy-body-text">
              Maineautoparts.com encourages you to review the privacy statements
              of websites you choose to link to from Maineautoparts.com so that
              you can understand how those websites collect, use and share your
              information. Maineautoparts.com is not responsible for the privacy
              statements or other content on websites outside of the
              MaineAutoParts website.
            </p>
            <h6 className="privacy-header-6">
              Use of your Personal Information
            </h6>
            <p className="privacy-body-text">
              Maineautoparts.com collects and uses your personal information to
              operate its website(s) and deliver the services you have
              requested.
            </p>
            <p className="privacy-body-text">
              Maineautoparts.com may also use your personally identifiable
              information to inform you of other products or services available
              from MaineAutoParts and its affiliates. MaineAutoParts may also
              contact you via surveys to conduct research about your opinion of
              current services or of potential new services that may be offered.
            </p>
            <p className="privacy-body-text">
              Maineautoparts.com does not sell, rent or lease its customer lists
              to third parties!
            </p>
            <p className="privacy-body-text">
              Maineautoparts.com may, from time to time, contact you on behalf
              of external business partners about a particular offering that may
              be of interest to you. In those cases, your unique personally
              identifiable information (e-mail, name, address, telephone number)
              is not transferred to the third party. Maineautoparts.com may
              share data with trusted partners to help perform statistical
              analysis, send you email or postal mail, provide customer support,
              or arrange for deliveries. All such third parties are prohibited
              from using your personal information except to provide these
              services to Maineautoparts.com, and they are required to maintain
              the confidentiality of your information.
            </p>
            <p className="privacy-body-text">
              Maineautoparts.com may keep track of the websites and pages our
              users visit within MaineAutoParts, in order to determine what
              Maineautoparts.com services are the most popular. This data is
              used to deliver customized content and advertising within
              Maineautoparts.com to customers whose behavior indicates that they
              are interested in a particular subject area.
            </p>
            <p className="privacy-body-text">
              Maineautoparts.com will disclose your personal information,
              without notice, only if required to do so by law or in the good
              faith belief that such action is necessary to:
            </p>
            <p className="privacy-special">
              conform to the edicts of the law or comply with legal process
              served on MaineAutoParts Logistics Company or the site;
            </p>
            <p className="privacy-special">
              protect and defend the rights or property of Maineautoparts.com;
              and,
            </p>
            <p className="privacy-special">
              Act under exigent circumstances to protect the personal safety of
              users of Maineautoparts.com, or the public.
            </p>
            <p className="privacy-body-text">
              MainAutoParts will deactivate any account with fake Personal
              Information, if reported and proven as stated, This is to ensure
              your safety in communicating and transacting.
            </p>
            <h6 className="privacy-header-6">
              Security of your Personal Information
            </h6>
            <p className="privacy-body-text">
              Maineautoparts.com uses modern encryption methods to secure your
              personal information from unauthorized access, use or disclosure.
            </p>
            <p className="privacy-body-text">
              When personal information (such as a credit card number) is
              transmitted to other websites, it is protected through the use of
              encryption, such as the Secure Sockets Layer (SSL) protocol.
            </p>
            <h6 className="privacy-header-6">Children Under 13</h6>
            <p className="privacy-body-text">
              Maineautoparts.com does not knowingly collect personally
              identifiable information from children under the age of thirteen.
              We therefore also ask you, if you are under 13 years old, please
              do not send us your personal information (for example, your name,
              address and email address). If however you still wish to use this
              website, you must ask your parent or guardian for permission to
              use this website.
            </p>
            <h6 className="privacy-header-6">Opt-Out &amp; Unsubscribe</h6>
            <p className="privacy-body-text">
              We respect your privacy and give you an opportunity to opt-out of
              receiving announcements of certain information. Users may opt-out
              of receiving any or all communications from MaineAutoParts by
              contacting us at Maineautoparts.com.
            </p>
            <h6 className="privacy-header-6">Changes to this Statement</h6>
            <p className="privacy-body-text">
              Maineautoparts.com will occasionally update this Statement of
              Privacy to reflect company and customer feedback.
              Maineautoparts.com encourages you to periodically review this
              Statement to be informed of how Maineautoparts.com is protecting
              your information.
            </p>
            <h6 className="privacy-header-6">Contact Information</h6>
            <p className="privacy-body-text">
              Maineautoparts.com welcomes your questions or comments regarding
              this Statement of Privacy. If you believe that MaineAutoParts has
              not adhered to this Statement, please contact MaineAutoParts{" "}
              <span className="here">
                <a className="aa-colo" href="/contact">
                  Here-
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Terms;

import React, { useEffect, useState } from "react";
import CatScreenDetails from "../components/Category/CatScreenDetails";
import CatScreenSideBar from "../components/Category/CatScreenSideBar";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Footer from "./Layout/Footer";

function Category(props) {
  let params = useParams();
  const [adverts, setAdverts] = useState([]);
  const [latests, setLatests] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [total, settotal] = useState(0);
  useEffect(() => {
    let link =
      "https://dashboard.maineautoparts.com/api/search-adverts?featured=0&category_id=" +
      params.id +
      "&brand_id=&dealer_id&minimumPrice=&maximumPrice&search=";
    axios.get(link).then((response) => {
      let ads = response.data.data;
      setNextPage(response.data.next_page_url);
      settotal(response.data.total);
      setAdverts(ads);
      setLoading(false);
    });
    axios
      .get(
        "https://dashboard.maineautoparts.com/api/search-adverts?featured=0&category_id=&brand_id=&dealer_id&minimumPrice=&maximumPrice&search="
      )
      .then((response) => {
        let ads = response.data.data;
        ads = ads.slice(0, 5);
        setLatests(ads);
      });
  }, [params]);

  return (
    <div>
      <div className="main-container container">
        <ul className="breadcrumb">
          <li>
            <Link to="/">
              <i className="fa fa-home"></i>
            </Link>
          </li>
          <li>
            <Link to="#">{params.category}</Link>
          </li>
        </ul>
        <div className="row">
          {/*Middle Part Start*/}
          <div id="content" className="col-md-9 col-sm-8">
            <CatScreenDetails
              ads={adverts}
              category={params.category}
              nextPageUrl={nextPage}
              loading={loading}
            />
          </div>
          {/*Side Bar*/}
          <CatScreenSideBar latests={latests} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Category;

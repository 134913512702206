import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

function Footer(props) {
  const { topCategories, categories } = useContext(AppContext);

  return (
    <footer className="footer-container typefooter-2">
      <div className="row-dark">
        <div className="row container container-top">
          <div className="col-lg-4 col-md-5 col-sm-5 col-xs-12 col-socials">
            <ul className="socials">
              <li className="facebook">
                <a href="#" target="_blank">
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li className="twitter">
                <a href="#" target="_blank">
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li className="instagram">
                <a href="#" target="_blank">
                  <i className="fa fa-instagram" />
                </a>
              </li>
              <li className="Youtube">
                <a href="#" target="_blank">
                  <i className="fa fa-youtube-play" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-8 col-md-7 col-sm-7 col-xs-12">
            <div className="module newsletter-footer1">
              <div className="newsletter">
                <h3 className="modtitle">Sign Up for Newsletter</h3>
                <div className="block_content">
                  <form
                    method="post"
                    id="signup"
                    name="signup"
                    className="form-group form-inline signup send-mail"
                  >
                    <div className="form-group">
                      <div className="input-box">
                        <input
                          type="email"
                          placeholder="Your email address..."
                          className="form-control"
                          id="txtemail"
                          name="txtemail"
                          size={55}
                        />
                      </div>
                      <div className="subcribe">
                        <button
                          className="btn btn-primary btn-default font-title"
                          type="submit"
                          name="submit"
                        >
                          <span>Subscribe</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row footer-middle">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-style">
            <div className="box-footer box-infos">
              <div className="module">
                <h3 className="modtitle">Contact us</h3>
                <div className="modcontent">
                  <ul className="list-icon">
                    <li>
                      <span className="icon pe-7s-map-marker" />
                      5611 Wellington Road, Suite 115, Gainesville, VA 20155
                    </li>
                    <li>
                      <span className="icon pe-7s-call" />{" "}
                      <a href="#">+234 912 639 9629</a>
                    </li>
                    <li>
                      <span className="icon pe-7s-mail" />
                      <a href="mailto:support@maineautoparts.com">
                        support@maineautoparts.com
                      </a>
                    </li>
                    <li>
                      <span className="icon pe-7s-alarm" />
                      24 / 7 Support
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-style">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-style">
                <div className="box-information box-footer">
                  <div className="module clearfix">
                    <h3 className="modtitle">Information</h3>
                    <div className="modcontent">
                      <ul className="menu">
                        <li>
                          <Link to="/about">Why us</Link>
                        </li>
                        <li>
                          <Link to="/faq">FAQ</Link>
                        </li>
                        <li>
                          <Link to="/career">Careers</Link>
                        </li>
                        <li>
                          <Link to="/terms-of-services">Terms of Services</Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-style">
                <div className="box-account box-footer">
                  <div className="module clearfix">
                    <h3 className="modtitle">My Account</h3>
                    <div className="modcontent">
                      <ul className="menu">
                        <li>
                          <Link to="/part-request">Make a Request</Link>
                        </li>
                        <li>
                          <Link to="/register">Getting Started</Link>
                        </li>
                        <li>
                          <a href="/login">Dealers Account</a>
                        </li>
                        <li>
                          <Link to="/faq">FAQs</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-clear">
                <div className="box-service box-footer">
                  <div className="module clearfix">
                    <h3 className="modtitle">Top Categories</h3>
                    <div className="modcontent">
                      <ul className="menu">
                        {categories.map((value, index) => {
                          return (
                            <li key={index}>
                              <Link
                                to={"/category/" + value.name + "/" + value?.id}
                              >
                                {value.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="copyright col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <p style={{ textAlign: "center" }}>
                MaineAutoparts © 2022. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*Back To Top*/}
      <div className="back-to-top">
        <i className="fa fa-angle-up" />
      </div>
    </footer>
  );
}

export default Footer;

import axios from "axios";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "./Layout/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Contact(props) {
  const params = useParams();
  const [name, setName] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [Phone, setPhone] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const submit = (e) => {
    e.preventDefault();

    setLoading(true);
    const formData = new FormData();
    formData.append("title", "contact form");
    formData.append("name", name);
    formData.append("email", Email);
    formData.append("phone", Phone);
    formData.append("description", description);
    formData.append("is_company", 1);
    axios
      .post("https://dashboard.maineautoparts.com/api/contact", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setErrors([]);
        setName("");
        setPhone("");
        setEmail("");
        setDescription("");
        toast.success("Message Sent!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        //// Run Job Schedule
        axios.get("https://dashboard.maineautoparts.com/api/run");
        setTimeout(function () {
          axios.get("https://dashboard.maineautoparts.com/api/run");
        }, 1000);
        setTimeout(function () {
          axios.get("https://dashboard.maineautoparts.com/api/run");
        }, 10000);
        //////////////////
      })
      .catch((error) => {
        toast.error("Error sending message", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        setErrors(error.response.data.errors);
      });
  };
  React.useEffect(() => {
    setErrors([]);
  }, [params]);
  return (
    <>
      <ToastContainer />
      <div className="container mb-100 page">
        <div className="row">
          <div className="col-lg-2 sm-no">
            <img
              src="/image/face1.ee56825e.png"
              className="img-fluid face float-icons-2"
              alt="face1"
            />
            <img
              src="/image/face2.ffcfda88.png"
              className="img-fluid face2 float-icons-3"
              alt="face2"
            />
            <img
              src="/image/face3.968da958.png"
              className="img-fluid face3 float-icons"
              alt="face3"
            />
          </div>
          <div className="col-lg-8">
            <div className="card-sign-up text-center">
              <h3
                className="careers-mx"
                style={{ padding: 0, margin: 0, marginBottom: 15 }}
              >
                Need help?
              </h3>
              <h3 className="form-title h-25">Contact Us</h3>
              <p
                className="mb-4"
                style={{ position: "relative", top: -18, textAlign: "center" }}
              >
                Fill out this form for assistance with pricing, uploads of your
                listings or any general enquiries you may have.
              </p>
              <form action="#" method="post">
                <input
                  name="name"
                  type="text"
                  className={[
                    "overide input-text ",
                    errors?.name ? " is-invalid" : "",
                  ]}
                  placeholder="Name *"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                {errors?.name != undefined ? (
                  <p className="error">{errors?.name[0]}</p>
                ) : (
                  ""
                )}
                <input
                  name="phone"
                  type="text"
                  placeholder="Mobile number *"
                  value={Phone}
                  onChange={(event) => setPhone(event.target.value)}
                  className={[
                    "overide input-number ",
                    errors?.phone ? " is-invalid" : "",
                  ]}
                />
                {errors?.phone != undefined ? (
                  <p className="error">{errors?.phone[0]}</p>
                ) : (
                  ""
                )}
                <input
                  name="email"
                  type="email"
                  className={[
                    "overide input-email ",
                    errors?.email ? " is-invalid" : "",
                  ]}
                  placeholder="Email address *"
                  value={Email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {errors?.email != undefined ? (
                  <p className="error">{errors?.email[0]}</p>
                ) : (
                  ""
                )}
                <textarea
                  name="description"
                  placeholder="Tell us anything"
                  value={description}
                  className={`
                  overide text-text
                  ${errors?.description ? "is-invalid " : ""},
                `}
                  onChange={(event) => setDescription(event.target.value)}
                ></textarea>
                {errors?.description != undefined ? (
                  <p className="error">{errors?.description[0]}</p>
                ) : (
                  ""
                )}
                <button type="submit" className="sign-up-btn" onClick={submit}>
                  Contact us
                  <span
                    className="spinner-border spinner-border-sm onPageloader"
                    role="status"
                    aria-hidden="true"
                    style={{ display: loading ? "inline-block" : "none" }}
                  ></span>
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-2">
            <img
              src="/image/face4.5e4430b4.png"
              className="img-fluid face4 float-icons-3"
              alt="face4"
            />
            <img
              src="/image/face5.46fcb240.png"
              className="img-fluid face5 float-icons-2"
              alt="face5"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

function CatProduct({ item, grid }) {
  const { formatNumber, favourite, favourites, getFavourites } =
    useContext(AppContext);
  const setFavourite = (id) => {
    item.isFavourite = !item.isFavourite;
    favourite(id);
  };
  return (
    <Link
      to={"/advert/" + item?.id}
      className="product-layout col-lg-4 col-md-4 col-sm-6 col-xs-12"
    >
      <div className="product-item-container item--static">
        <div className="left-block">
          <div className="product-image-container second_img">
            <div title={item.title} className="img-hold">
              <img
                src={item.images[0]}
                className="img-1 img-responsive"
                alt="image1"
              />
              <img
                src={
                  item.images[1] != undefined ? item.images[1] : item.images[0]
                }
                className="img-2 img-responsive"
                alt="image2"
              />
            </div>
          </div>
          <span className="label-product label-new">
            {item?.condition?.name}
          </span>
          {/*quickview*/}
          <div className="so-quickview">
            <div
              className="iframe-link btn-button quickview quickview_handler visible-lg"
              title="Quick view"
              data-fancybox-type="iframe"
            >
              <i className="fa fa-search" />
              <span>Quick view</span>
            </div>
          </div>
          {/*end quickview*/}
        </div>

        <div className="price-wrap">
          <span className="price">₦{formatNumber(item.price)}</span>
          <span className="condit">{item.condition.name}</span>
        </div>
        <div className="right-block">
          <div
            className={[
              "button-group cartinfo--static ",
              !grid ? " hidden" : "",
            ]}
          >
            <button type="button" className="addToCart" title="View seller">
              <span>By {item.dealer_name}</span>
            </button>
            <button
              type="button"
              className="compare btn-button"
              title="Call seller"
              onClick={() => {
                window.open("tel:" + item.dealer.phone, "_self");
              }}
            >
              <i className="fa fa-phone" />
            </button>
          </div>
          <h4>{item.title}</h4>

          <div className="description item-desc hidden">
            <p>{item.description}</p>
          </div>
          <div className={["list-block ", grid ? " hidden" : ""]}>
            <button
              className="addToCart btn-button"
              type="button"
              title="Add to Wishlist"
            >
              <i className="fa fa-shopping-basket" />
            </button>
            <button
              className="wishlist btn-button"
              type="button"
              title="Add to Wish List"
            >
              <i className="fa fa-heart" />
            </button>
            <button
              className="compare btn-button"
              type="button"
              title="Call seller"
              onClick={() => {
                window.open("tel:" + item.dealer.phone, "_self");
              }}
            >
              <i className="fa fa-phone" />
            </button>
            {/*quickview*/}
            <div
              className="iframe-link btn-button quickview quickview_handler visible-lg"
              title="Quick view"
              data-fancybox-type="iframe"
            >
              <i className="fa fa-eye" />
            </div>
            {/*end quickview*/}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CatProduct;
